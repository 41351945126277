Ext.QuickTips.init();
var Common = {
    isRedirecting: false,
    afterRegisterMonths: function() {
        var date = new Date(_TARIFF_DATE_REGISTER);
        var now = new Date();

        return  now.getMonth() - date.getMonth() +
            (12 * (now.getFullYear() - date.getFullYear()));
    },
    afterRegisterDays: function() {
        var date = new Date(_TARIFF_DATE_REGISTER);
        var now = new Date();

        var diffTime = Math.abs(now - date);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
    stripHtml: function(html) {
        if (typeof html == 'undefined') {
            return '';
        }

        var tmp = document.createElement("DIV");
        tmp.innerHTML = html;

        var result =  tmp.textContent || tmp.innerText || "";

        return result.trim();
    }
    , showMessage: function(title, msg, isError, fn) {
        if (msg == null) {
            return;
        }

        if (isError) {
            var massageBox = Ext.MessageBox.show({
                title: title,
                msg: msg,
                buttons: Ext.Msg.OK,
                minWidth: 280,
                icon: Ext.MessageBox.ERROR,
                fn: fn || Ext.emptyFn
            });
        } else {
            Ext.MsgManager.alert(title, msg);
        }
    }
    , showErrors: function (response) {
        var globalCheck = isGlobalAjaxMessage(response);

        if (response.isGlobalMessage || globalCheck.isGlobal) {
            return;
        }
        var resp;
        try{
            resp = !Ext.isEmpty(response.responseText) ? Ext.decode(response.responseText) : response;
        } catch(e) {
            resp = {
                is_error: true
                , messages: [_t('Ошибка сервера')]
            };
        }
        if (resp.is_error) {
			if(typeof(Ext) != 'undefined' && typeof(Ext.app) != 'undefined' && typeof(Ext.app.PanelsManager) != 'undefined'){
                VetManager.GlobalEvents.fireEvent('vm_error_message', {
                    module: Ext.app.PanelsManager.getCurMainModuleName(),
					message: this.getMessage(resp.messages)
				});
			}
            if (resp.messages.length > 0) {
                setTimeout(
                    this.showMessage.createDelegate(this, [_t('Информационное сообщение'), this.getMessage(resp.messages), resp.is_error])
                    , 10
                );
            }
            return true;
        }
        if (resp.messages) {
            if (resp.messages.length > 0) {
                setTimeout(
                    this.showMessage.createDelegate(this, [_t('Системное сообщение'), this.getMessage(resp.messages), resp.is_error])
                    , 10
                );
                return false;
            }
        }
        return false;
    }
    , getMonthTranslated: function (v) {
        var monthIndex = parseInt(v);

        var dt = new Date();

        try {
            if (Ext.isDate(v)) {
                dt = v;
            } else {
                dt = Date.parseDate(v, "Y-m-d H:i:s");
            }

            monthIndex = parseInt(dt.format('m'));
        } catch(err) {}

        var monthArr = {
            1: _t('Январь'),
            2: _t('Февраль'),
            3: _t('Март'),
            4: _t('Апрель'),
            5: _t('Май'),
            6: _t('Июнь'),
            7: _t('Июль'),
            8: _t('Август'),
            9: _t('Сентябрь'),
            10: _t('Октябрь'),
            11: _t('Ноябрь'),
            12: _t('Декабрь')
        };

        return monthArr[monthIndex];
    }
    , dateTranslatedAbbreviated: function (date, defValue) {
        date = new Date(date);
        var monthName = this.getMonthTranslated(date);
        try {
            return date.format('d') + " " + monthName.substr(0, 3) + " " + date.format('Y');
        } catch (e) {
            console.error(e);
            return defValue;
        }
    }
    , getMessage: function(messages){
        if (messages == null) {
            return null;
        }

        var msg = '';

        for (var i = 0; i < messages.length; i++) {
            msg += messages[i] + '<br />';
        }
        return msg;
    }
    , setFormLabels: function(form, data, prefix) {
        for (var i in data) {
            if (!data.hasOwnProperty(i)) continue;
            var label = (prefix.search(/\[/) != -1) ? form.findById(prefix+i+']') : form.findById(i + '_' + prefix);
            if (label) label.setText(data[i]);
        }
    }
    , _getSelector: function(){
        if (!this) { return; }
        var mybtnobj = function(config){
            this.selection = config.selection || null;
            this.action = config.action || null;
            this.button = config.button || null;
            this.enablerFunc = config.enablerFunc || null;
        };
        var mybtnsArr = [];
        var sm = this.getSelectionModel();
        var selCount = sm.getCount();
        var access = this;
        if (this.buttons ) {
            for (var i = 0; i < this.buttons.length; i++) {
                mybtnsArr.push(new mybtnobj({
                    selection: this.buttons[i].selection
                    , action: this.buttons[i].action
                    , button: this.buttons[i]
                    , enablerFunc: this.buttons[i].enablerFunc
                }));
            }
        }

        while(access && !access.access){
            access = access.ownerCt;
        }

        if (!access) { return; }
        if (this.toolbars) {
            for (var j = 0; j < this.toolbars.length; j++) {
                this.toolbars[j].items.each(function(item){
                    if (Ext.isDefined(item.selection)) {
                        mybtnsArr.push(new mybtnobj({
                            selection: item.selection
                            , action: item.action
                            , button: item
                            , enablerFunc: item.enablerFunc
                        }));
                    }
                });
            }
        }

        access = access.access;

        for (var i = 0; i < mybtnsArr.length; i++) {
            switch (selCount) {
                case 0:
                    mybtnsArr[i].button.setDisabled(true);
                    if('0' == mybtnsArr[i].selection && access[mybtnsArr[i].action]){
                        mybtnsArr[i].button.setDisabled(false);
                    }
                break;
                case 1:
                    if (access[mybtnsArr[i].action]) {
                        mybtnsArr[i].button.setDisabled(false);
                    }
                break;
                default:
                    mybtnsArr[i].button.setDisabled(true);
                    if ((('N' == mybtnsArr[i].selection) || ('0' == mybtnsArr[i].selection)) && access[mybtnsArr[i].action]) {
                        mybtnsArr[i].button.setDisabled(false);
                    }
                    if (selCount > 1 && mybtnsArr[i].selection == '2' && access[mybtnsArr[i].action]) {
                        mybtnsArr[i].button.setDisabled(false);
                    }
                break;
            }
            if ( mybtnsArr[i].enablerFunc && typeof mybtnsArr[i].enablerFunc == 'function' ) {
                if (!mybtnsArr[i].enablerFunc.call(this, mybtnsArr[i].button)) {
                    mybtnsArr[i].button.setDisabled(true);
                } else if(access[mybtnsArr[i].action]) {
                    mybtnsArr[i].button.setDisabled(false);
                }
            }
        }
        mybtnsArr = null;
    }
    , VMDateFormatFull: 'd.m.Y H:i'
    , VMDateFormat: 'd.m.Y'
    , renderDateTime: function(v){
        if (v == '0000-00-00 00:00:00') {
            return '00.00.0000 00:00';
        }

        var dt = new Date();
        try {
            if (Ext.isDate(v)){
                dt = v;
            }else{
                dt = Date.parseDate(v, "Y-m-d H:i:s");
            }
            return dt.format(Common.VMDateFormatFull);
        } catch(err) {
            return v;
        }
    }
    , renderTime: function(v, timeFormat){
        if (v == '0000-00-00 00:00:00') {
            return '00.00.0000 00:00';
        }

        var dt = new Date();
        try {
            if (Ext.isDate(v)){
                dt = v;
            }else{
                dt = Date.parseDate(v, "H:i");
            }
            return (typeof timeFormat == 'undefined') ? dt.format(Common.VMDateFormatFull) : dt.format(timeFormat);
        } catch(err) {
            return v;
        }
    }
    , renderTimeFromNumber: function(num) {
        num = parseInt(num);
        if (isNaN(num)) {
            return '';
        }
        return Math.floor(num/60) + ':' + ('00' + (num % 60)).substr(-2);
    }
    , renderSexFull: function(v){
        var res = '';
        switch (v) {
            case 'male':
                res = _t('Самец');
                break;
            case 'female':
                res = _t('Самка');
                break;
            case 'castrated':
                res = _t('Кастрирован');
                break;
            case 'sterilized':
                res = _t('Стерилизована');
                break;
            default:
                res = _t('Не известен');
                break;
        }
        return res;
    },
    renderAgeShort: function(v){
        var age = Ext.calcAgeObj(v);
        if (age.y > 0) {
            return (_LANGUAGE == 'ua'? (age.y > 0 ? age.y + _t('г. ') : '') : (age.y > 0 ? age.y + (age.y < 5 || age.y > 20 && age.y%10 < 5 && age.y%10 > 0 ? _t('г. ') : _t('л. ')) : ''));
        }
        if (age.m > 0) {
            return age.m + _t('м. ');
        }
        if (age.w > 0) {
            return age.w + _t('н. ');
        }
        if (age.d > 0) {
            return age.d + _t('д. ');
        }
        return '';
    },
    renderSexIcon: function (v, cssClass) {
        var me = this;
        cssClass = cssClass || 'ticket-sex-icon';
        var imgSrc = '';
        switch (v) {
            case 'male':
                imgSrc = me.getImageFullPath('male.svg');
                break;
            case 'female':
                imgSrc = me.getImageFullPath('female.svg');
                break;
            case 'castrated':
                imgSrc = me.getImageFullPath('castrated.svg');
                break;
            case 'sterilized':
                imgSrc = me.getImageFullPath('sterilized.svg');
                break;
            default:
                imgSrc = me.getImageFullPath("ui/desktop/images/s.gif");
                break;
        }
        var html = "<div class='" + cssClass + "' " +
            "style='background: url(" + imgSrc + ") center center no-repeat;'></div>";
        return html;
    }
    , renderSexShort: function(v){
        var res = '';
        switch (v) {
            case 'male':
                res = _t('М');
                break;
            case 'female':
                res = _t('Ж');
                break;
            case 'castrated':
                res = _t('К');
                break;
            case 'sterilized':
                res = _t('с');
                break;
            default:
                res = _t('Н');
                break;
        }
        return res;
    },
    renderFiscalPrintType: function(v) {
        var res = '';

        switch (v) {
            case 'combination_header_as_good':
                res = _t('Только имя комбинации (как товар)');
                break;
            case 'combination_header_as_service':
                res = _t('Только имя комбинации (как услуга)');
                break;
            case 'service_detail':
                res = _t('Полная детализация только по услугам');
                break;
            case 'normal':
                res = _t('Все пункты');
                break;
            case 'ask_user':
                res = _t('Спрашивать');
                break;
            default:
                res = '';
                break;
        }

        return res;
    },
    renderDate: function(v){
        if (v == '0000-00-00') {
            return '00.00.0000';
        }

        var dt = new Date();

        try {
            if (Ext.isDate(v)){
                dt = v;
            }else{
                dt = Date.parseDate(v, "Y-m-d");
            }
            return dt.format(Common.VMDateFormat);
        } catch(err) {
            return v;
        }
    },
    renderDateWithoutYearText: function(v) {
        if (v == '0000-00-00') {
            return '00.00.0000';
        }

        var dt = new Date();

        try {
            if (Ext.isDate(v)) {
                dt = v;
            } else {
                dt = Date.parseDate(v, "Y-m-d");

                if (typeof dt == 'undefined') {
                    dt = Date.parseDate(v, "Y-m-d H:i:s");
                }
            }

            return dt.format("d M").toLocaleLowerCase();
        } catch(err) {
            return v;
        }
    },
    renderDateText: function(v) {
        if (v == '0000-00-00') {
            return '00.00.0000';
        }

        var dt = new Date();

        try {
            if (Ext.isDate(v)) {
                dt = v;
            } else {
                dt = Date.parseDate(v, "Y-m-d");

                if (typeof dt == 'undefined') {
                    dt = Date.parseDate(v, "Y-m-d H:i:s");
                }
            }

            return dt.format("d M Y").toLocaleLowerCase();
        } catch(err) {
            return v;
        }
    },
    renderPrettyDate: function(v, format) {
        v = this.convertToDate(v, format);
        if (Ext.isDate(v)) {
            let diff = this.dateDiff(v);

            if (diff.y === 0 && diff.m === 0 && diff.d === 0) { // today
                return `${_t('Сегодня')} ${v.format('H:i')}`;
            }
            if (diff.y === 0 && diff.m === 0 && diff.d === 1) { // yesterday
                return `${_t('Вчера')} ${v.format('H:i')}`;
            }
            if (diff.y > 0) {
                return `${v.format('d M Y').toLocaleLowerCase()}`
            }
            return `${v.format('d M H:i').toLocaleLowerCase()}`
        }
        return v;
    },
    dateDiff: function(startDate, endDate) {
        if (!Ext.isDate(startDate)) {
            startDate = new Date(startDate);
        }
        if (!endDate) {
            endDate = new Date();
        } else if (!Ext.isDate(endDate)) {
            endDate = new Date(endDate);
        }

        startDate = (new Date(startDate)).clearTime();
        endDate = (new Date(endDate)).clearTime();

        if (startDate > endDate) {
            let swap = startDate;
            startDate = endDate;
            endDate = swap;
        }
        let startYear = startDate.getFullYear();
        let february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
        let daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        let yearDiff = endDate.getFullYear() - startYear;
        let monthDiff = endDate.getMonth() - startDate.getMonth();
        if (monthDiff < 0) {
            yearDiff--;
            monthDiff += 12;
        }
        let dayDiff = endDate.getDate() - startDate.getDate();
        if (dayDiff < 0) {
            if (monthDiff > 0) {
                monthDiff--;
            } else {
                yearDiff--;
                monthDiff = 11;
            }
            dayDiff += daysInMonth[startDate.getMonth()];
        }

        return {y: yearDiff, m: monthDiff, d: dayDiff};
    }
    , convertToDate: function(v, f) {
        let dt;
        if (Ext.isDate(v)) {
            return v;
        } else {
            try {
                if (Ext.isString(v)) {
                    if (v.length > 10) {
                        f = f || 'Y-m-d H:i:s';
                        dt = Date.parseDate(v, f);
                    } else {
                        f = f || 'Y-m-d';
                        dt = Date.parseDate(v, f);
                    }
                }
            } catch(err) {
                console.error(err);
            }
            return dt;
        }
    }
    , renderHoursFromFloat: function(v) {
        var hours = parseFloat(v);
        if (isNaN(hours)) {
            return v;
        }

        var minutes = Math.round(60 * (hours - Math.floor(hours)));
        hours = Math.floor(hours);

        return hours + ':' + ("0" + minutes).slice(-2);
    }
    , renderStatus: function(v){
        var changedValue;
        switch(v) {
            case 'save':
                changedValue = _t('Сохранен');
                break;
            case 'exec':
                changedValue = _t('Исполнен');
                break;
            case 'rollback':
                changedValue = '<span style="font-style: italic">'+ _t('Возврат')+'</span>';
                break;
            case 'deleted':
                changedValue = '<span style="color: red; font-weight: bold;">'+_t('Удален')+'</span>';
                break;
            case 'closed':
                changedValue = _t('Закрыт');
                break;
            case 'ACTIVE':
                changedValue = _t('Активно');
                break;
            case 'DISABLED':
                changedValue = _t('Не активно');
                break;
            case 'NO ANSWER':
                changedValue = _t('Без ответа');
                break;
            case 'FAILED':
                changedValue = _t('Неудачный');
                break;
            case 'BUSY':
                changedValue = _t('Занят');
                break;
            case 'ANSWERED':
                changedValue = _t('Отвечен');
                break;
            case 'UNKNOWN':
                changedValue = _t('Не известен');
                break;
            case 'directed':
                changedValue = '<span style="color: blue;">'+_t('Ожидает')+'</span>';
                break;
            case 'accepted':
                changedValue = '<span style="color: green; font-weight: bold;">'+_t('Принятый')+'</span>';
                break;
            case 'in_treatment':
                changedValue = '<span style="color: black; font-weight: bold;">'+_t('У врача')+'</span>';
                break;
            case 'normal':
                changedValue = '<div style="background-color: #CCFFCC;width: 100px;font-weight: bold;display: block;padding-left: 10px;">'+_t('Нормально')+'</div>';
                break;
            case 'warning':
                changedValue = '<div style="background-color: #FFFFCC;width: 100px;font-weight: bold;display: block;padding-left: 10px;">'+_t('Важно')+'</div>';
                break;
            case 'critical':
                changedValue = '<div style="background-color: #FFCCCC;width: 100px;font-weight: bold;display: block;padding-left: 10px;">'+_t('Критично')+'</div>';
                break;

        }
        return changedValue;
    }
    , atolEventRenderer: function (v) {
        var res = '';

        switch(v) {
            case 'customReport':
                res = _t('Отчет');
                break;
            case 'customPaymentRun':
            case 'customReturnGood':
                res = _t('Вручную');
                break;
            case 'paymentRun':
                res = _t('Оплата');
                break;
            case 'paymentRollback':
                res = _t(`Возврат оплаты`);
                break;
            case 'correction':
                res = _t(`Чек коррекции`);
                break;
            case 'returnGood':
                res = _t('Возврат товаров');
                break;
            case 'smenaStart':
                res = _t('Старт смены');
                break;
            case 'smenaEnd':
                res = _t('Конец смены');
                break;
            case 'balance':
                res = _t(`Баланс операции`);
                break;
            case 'cashIncome':
                res = _t(`Внесение денег в кассу`);
                break;
            case 'cashOutcome':
                res = _t(`Вывод денег из кассы`);
                break;
        }

        return res;
    }
    , atolStatusRenderer: function (v) {
        var res = '';

        switch(v) {
            case 'save':
                res = '<span style="color: blue;">'+_t('Сохранен')+'</span>';
                break;
            case 'sended':
                res = '<span style="color: black; font-style: italic;">'+_t('Отправлен')+'</span>';
                break;
            case 'error':
                res = '<span style="color: red; font-weight: bold;">'+_t('Ошибка')+'</span>';
                break;
            case 'exec':
                res = '<span style="color: black; font-weight: bold;">'+_t('Выполнен')+'</span>';
                break;
            case 'canceled':
                res = '<span style="color: red;">'+_t('Отменен')+'</span>';
                break;
            case 'double':
                res = '<span style="color: red;">'+_t(`Дубликат (не печатается)`)+'</span>';
                break;
            case 'in_queue':
                res = '<span style="color: black; font-style: italic;">'+ _t('В очереди') +'</span>'; //TODO: translate
                break;
        }

        return res;
    }
    , rendererTimeStampSec: function(v) {
        if (Ext.isEmpty(v)) {
            return v;
        }
        v = v * 1000;
        var date = new Date(v);
        return Common.renderDateTime(date);
    }
    , rendererWaitMinute: function(v, meta, rec) {
        if (Ext.inArray(['in_treatment','accepted'], rec.get('status'))) {
            return '';
        }
        var date1 = Date.parseDate(v, "Y-m-d H:i:s", true)
            , now = new Date();
        if (Ext.isDate(date1)) {
            var diff = now - date1
                , minutes = Math.floor((diff/1000)/60);
            return (minutes > 0) ? minutes : 0;
        } else {
            return '';
        }
    },
    renderCassaType: function(v) {
        var name = v, tip;
        switch (v) {
            case 'operating':
                tip = _t('Операционная');
                break;
            case 'bank':
                tip = _t('Банк');
                break;
            default:
                name = 'safe';
                tip = _t('Сейф');
                break;
        }
        return Common.getImageHTML(
            name + '.svg',
            'height:20px; width:20px;',
            'ext:qtip="' + tip + '"'
        );
    },

    rendererYesNo: function(v){
        return (v == 1) ? _t('Да') : _t('Нет');
    },
    rendererMoney: function(money){
        return Common.renderMoney(money, 'default');
    },
    rendererMoneyCassa: function(money){
        return Common.renderMoney(money, 'cassa');
    },
    rendererMoneyDenomination: function(money){
        var denomination = GlobalProperties.get('denomination_value');
        return Common.renderMoney(money * denomination, 'default');
    }
    , rendererNumber: function(number){
        var v = parseFloat(number);
        if (isNaN(v)) {
            v = number;
        }
        return v;
    }
    , rendererPetInfo: function(alias, meta, rec) {
        var petInfo = alias
            , age = rec.get('age')
            , type = rec.get('type')
            , breed = rec.get('breed');
        if (age) { petInfo += ', ' + Ext.calcAge(age); }
        if (type) { petInfo += ', ' + type; }
        if (breed) { petInfo += ', ' + breed; }

        return petInfo;
    }
    , renderMoney: function(money, module, digits) {
        var currencyName = Ext.app.properties.get('currency-short', '');
        currencyName = currencyName == '' ? '' : currencyName + ' ';
        var currencyAlign = Ext.app.properties.get('currency-align', 'right');
        var rounded = this.splitMoney(Common.roundMoney(money, module, digits))
        if (currencyAlign === 'left') {
            return currencyName + ' ' + rounded;
        }else{
            return rounded + ' ' + currencyName;
        }
    },

    renderMoneyWithStyles: function(money, moneyStyle, currencyShortStyle, iconBefore, module, digits) {
        var currencyName = Ext.app.properties.get('currency-short', '');
        currencyName = currencyName == '' ? '' : currencyName + ' ';
        var currencyAlign = Ext.app.properties.get('currency-align', 'right');
        var rounded = this.splitMoney(Common.roundMoney(money, module, digits));

        if(moneyStyle) {
            rounded = '<span style="'+moneyStyle+'">' + rounded + '</span>'
        }

        if (currencyShortStyle) {
            currencyName = '<span style="' + currencyShortStyle + '">' + currencyName + '</span>'
        }

        var result = '';
        if (currencyAlign === 'left') {
            result = currencyName + ' ' + rounded;
        }else{
            result= rounded + ' ' + currencyName;
        }

        if(iconBefore){
            result = '<img style="margin-right: 5px;" src="'+Common.getImageFullPath(iconBefore)+'"/>' + result;
        }
        return result;
    }

    , renderClientLink: function(v,m,record) {
        if (Ext.isEmpty(record.get('client_id'))) {
            return v;
        }

        var link = String.format('<a href="#" onclick="Common.openClient({0}); return false;">{1}</a>', record.get('client_id'), v);

        return link;
    }
    , getRendererInvoiceLink: function(idsField, numbersField) {
        return function (v, meta, rec) {
            var ids = rec.get(idsField).toString().split(','),
                numbers = rec.get(numbersField).toString().split(','),
                text = [];
            Ext.each(ids, function(id, i) {
                id = parseInt(id);
                if (!isNaN(id) && id > 0) {
                    text.push(String.format('<a href="#" onclick="Common.openInvoice({0}); return false;">{1}</a>', id, numbers[i]));
                }
            });
            if (text.length > 0) {
                return text.join(',');
            } else {
                return v;
            }
        }
    }
    , renderInvoiceStatus: function(v, m, rec) {
        var status = rec.get('status'),
            result,
            tip;

        if (Ext.isEmpty(status)) {
            status = rec.get('pay_status');
        }

        switch (status) {
            case 'deleted':
                tip = result = '<span style="color: red; font-weight: bold;">'+_t('Удален')+'</span>';
                break;
            case 'archived':
                tip = result = '<span style="color: red; ">'+_t('Архив')+'</span>';
                break;
            case 'rollback':
                tip = result = '<span style="color: red;">'+ _t('Откачен') +'</span>';
                break;
            case 'save':
                tip = result = '<span style="color: blue;">'+_t('Отложен')+'</span>';
                break;
            case 'exec':
                tip = result = _t('Исполнен');
                break;
            case 'closed':
                result = '<div class="invoice-closed-status-cell">' + _t('Закрыт') + '</div>';
                tip = _t('Закрыт');
                break;
            default:
                // tip = result = _t('Исполнен'); // какой комплит???
                tip = result = _t('Не известно');
                break;
        }
        if (!this.showTooltip) {
            m.attr = 'ext:qtip="' + Ext.util.Format.htmlEncode(tip) + '"';
        }
        return result;
    },
    getUIImagesPath: function () {
        return 'ui/resources/images_new/';
    },
    getUIBrandImagesPath: function () {
        const BRAND = 'vetmanager';
        return  `ui/resources/images_new/brand/${BRAND}/`;
    },
    getImageFullPath: function (imageName) {
        return `${imageName.startsWith('brand_') ? this.getUIBrandImagesPath() : this.getUIImagesPath()}${imageName}`;
    },
    splitMoney: function(money){
        let useSplit = Ext.app.properties.get('currency-split', false),
            moneySplitter = Ext.app.properties.get('currency-split-splitter', ' ');
        if (this.valueToBool(useSplit)) {
            money += '';
            let x = money.split('.'),
                x1 = x[0],
                x2 = x.length > 1 ? '.' + x[1] : '';

            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + moneySplitter + '$2');
            }
            return x1 + x2;
        } else {
            return money;
        }
    },
    getDefaultDigits(digits, module){
        if (!Ext.isDefined(digits)) {
            let defaultDigits = 2;
            let propertyName = 'digits-' + (module || 'default');
            let propertyDefaultValue = Ext.app.properties.get('digits-default', defaultDigits);
            digits = Ext.app.properties.get(propertyName, propertyDefaultValue);
            digits = isNaN(digits) ? defaultDigits : digits;
        }
        return digits;
    },
    roundMoney: function(money, module, digits){
        digits = this.getDefaultDigits(digits, module);
        return Common.roundMoneyD(money, module, digits).toFixed(digits);
    },
    roundMoneyD: function(money, module, digits) {
        digits = this.getDefaultDigits(digits, module);
        money = parseFloat(money);
        money = isNaN(money) ? 0 : money + 0.000001;
        let p = Math.pow(10, digits);
        return Math.round(money * p) / p;
    },
    roundQuantityD: function(quantity, module, digits) {
        return parseFloat(this.roundQuantity(quantity, module, digits));
    }
    , renderQuantity: function(q) {
        return Common.roundQuantity(q, 'default');
    }
    , roundQuantity: function(quantity, module, digits){
        var defaultDigits = 3;
        module = module || 'default';
        if (!Ext.isDefined(digits)){
            digits = Ext.app.properties.get('digits-quantity-' + module, Ext.app.properties.get('digits-quantity-default', defaultDigits)) * 1;
            digits = isNaN(digits) ? defaultDigits : digits;
        }
        quantity = isNaN(parseFloat(quantity)) ? 0 : parseFloat(quantity) + 0.000001;
        return quantity.toFixed(digits);
    }
    , getDiscountAmount: function(fullAmount, percent) {
        return parseFloat(fullAmount - this.roundMoney(fullAmount - (fullAmount * percent / 100)));
    }
    , getIncreaseAmount: function(fullAmount, percent) {
        return parseFloat(this.roundMoney(fullAmount + (fullAmount * percent / 100)) - fullAmount);
    }
    , getCellComboRenderer: function(combo) {
        var rendererObject = new (function(){
            this.render = function(value) {
                var idx = combo.getStore().find(combo.valueField, value);
                if (idx == -1) return value;
                var rec = combo.getStore().getAt(idx);
                return rec.get(combo.displayField);
            };
        });
        return rendererObject.render;
    }
    , getMinimizedStoreDocumentGoods: function(store) {
        var goods = [];

        store.each(function(row) {
            var quantity_fakt = (null != row.get('quantity_fakt')) ? row.get('quantity_fakt')*1 : 0;
            var prihod_id = (null != row.get('prihod_id')) ? row.get('prihod_id')*1 : 0;
            var characteristic_id = (null != row.get('characteristic_id')) ? row.get('characteristic_id')*1 : 0;
            var good_id = (null != row.get('good_id')) ? row.get('good_id')*1 : 0;
            var price = (null != row.get('price')) ? (row.get('price_no_nds') != null ? row.get('price_no_nds')*1 : row.get('price')*1) : 0;

            if (row.get('party_characteristic_id') != null && row.get('party_characteristic_id') != '') {
                var subres = row.get('party_characteristic_id').split('_');
                if (subres.length == 2) {
                    prihod_id = subres[0]*1;
                    characteristic_id = subres[1]*1;
                }
            }
            var result = {
                gi: good_id,
                pi: prihod_id,
                q: row.get('quantity')*1,
                qf: quantity_fakt,
                ci: characteristic_id,
                pr: price,
                oci: parseInt(row.get('old_characteristic_id'))
            };

            if (null != row.get('cost_no_nds') && null != row.get('price_no_nds') && null != row.get('stavka_nds_percent') && null != row.get('sum_nds')) {
                var cost_no_nds = (null != row.get('cost_no_nds')) ? row.get('cost_no_nds')*1 : 0
                    , cost = (null != row.get('cost')) ? row.get('cost')*1 : 0
                    , price_no_nds = (null != row.get('price_no_nds')) ? row.get('price_no_nds')*1 : 0
                    , stavka_nds_percent = (null != row.get('stavka_nds_percent')) ? row.get('stavka_nds_percent')*1 : 0
                    , sum_nds = (null != row.get('sum_nds')) ? row.get('sum_nds')*1 : 0;

                result.cnn = cost_no_nds;
                result.pnn = price_no_nds;
                result.snp = stavka_nds_percent;
                result.sn = sum_nds;
                result.c = cost;
            }

            goods.push(result);
        }, this);

        return goods;
    }
    , colorHexToRGBA: function(hex, alpha) {
        alpha = alpha || 1;
        var rgb = hex.replace('#', '').match(/(.{2})/g);

        var j = 3;
        while (j--) {
            rgb[j] = parseInt(rgb[j], 16);
        }
        return rgb.join(', ') + ', ' + alpha.toString();
    }
    , openInvoice: function(invoiceId) {
        VMTools.openInvoice(invoiceId, 'from-app-' + Ext.id());
    }
    , openClient: function(clientId) {
        VMTools.openClientProfile( 'from-app-' + Ext.id(), clientId);
    }
    , openMedCard: function(clientId, petId, documentId) {
        VMTools.openMedCardRecord('from-app-' + Ext.id(), clientId, petId, documentId);
    }
    , showMedCard: function(documentId) {
        VMTools.showMedCard('from-app-' + Ext.id(), documentId);
    }
    , openAdmission: function(admissionId) {
        VMTools.editAdmmisionForClient('from-app-' + Ext.id(), admissionId);
    }
    , openTicket: function(documentId) {
        Ext.ux.Ticket.Module.edit({id: documentId});
    }
    , addInvoice: function(clientId, petId, callback) {
        VMTools.addInvoice('from-app-' + Ext.id(), clientId, petId, null, null, callback);
    }
    , addTicket: function(type, clientId, petId, callback) {
        Ext.ux.Ticket.Module.add({
            type: type,
            client_id: clientId,
            pet_id: petId
        }, callback);
    }
    , addAdmission: function(clientId, petId, callback) {
        VMTools.addAdmissionForClient('from-app-' + Ext.id(), clientId, petId, null, callback)
    }

    , showGlobalMessage: function(params) {
        if (this.isRedirecting) {
            return;
        }
        if (params.GLOBAL_MESSAGE_TYPE) {
            switch (params.GLOBAL_MESSAGE_TYPE) {
                case 'session_is_locked':
                    Ext.UserSessionChange.showLockWindow(true);
                break;
                case 'support_mode_is_off':
                    this.showMessage(_t('ВНИМАНИЕ!!!')
                        , _t('Пользователь отключил доступ для поддержки!')
                        , true, function() {
                            this.isRedirecting = true;
                            Ext.LockDocument.unlockAll(function() {
                                window.location.reload();
                            });
                        });
                    break;
                case 'vetacademy_reponse_error':
                    console.log(params.message_error);
                    this.showMessage(_t('ВНИМАНИЕ!!!')
                        , _t("При отправке баллов возникла ошибка со стороны Ветакадемии.") +
                        "<br> "+ _t("Ошибка") +": " + params.message_error + "<br>" +
                        _t("Сообщите о данной проблеме на email")+": <a href='mailto: contact.ru@royalcanin.com'>contact.ru@royalcanin.com</a>"

                , true);
                    break;
                case 'auth_info_is_changed':
                    Ext.connectSessionChecker.stop();
                    window.onbeforeunload = null;

                    this.showMessage(
                        _t('Внимание').toUpperCase() + '!!!'
                        , _t('Была потеряна сессия авторизации, или в другой вкладке был произведен вход другую клинику и/или под другим пользователем.') +'<br />'+ _t('Страницу необходимо обновить.'), true, function() {
                        this.isRedirecting = true;
                        Ext.LockDocument.unlockAll(function() {
                            window.location.reload();
                        });
                    });
                    break;
                case 'clinic_is_changed':
                    Ext.connectSessionChecker.stop();
                    window.onbeforeunload = null;

                    this.showMessage(
                        _t('Внимание').toUpperCase() + '!!!'
                        , _t('В одной из вкладок вошли в другую клинику.') +'<br />'+ _t('Страницу необходимо обновить.'), true, function() {
                            this.isRedirecting = true;
                            Ext.LockDocument.unlockAll(function() {
                                window.location.reload();
                            });
                        });
                    break;
                case 'user_is_changed':
                    Ext.connectSessionChecker.stop();
                    window.onbeforeunload = null;

                    this.showMessage(
                        _t('Внимание').toUpperCase() + '!!!'
                        , _t('В одной из вкладок вошли под другим пользователем.') +'<br />'+ _t('Страницу необходимо обновить.'), true, function() {
                            this.isRedirecting = true;
                            Ext.LockDocument.unlockAll(function() {
                                window.location.reload();
                            });
                        });
                    break;
                case 'tariff_error':
                    if (params.userLimit) {
                        Tariff.Common.limitUserErrorWin(Ext.connectSessionChecker, params.userMaxCount, params.userCount, true);
                    } else {
                        this.showMessage(
                            _t('Внимание').toUpperCase() + '!!!'
                            , _t('Ошибка тарифа') +'!<br/><br/>'+ _t('Истек срок тарифа, или превышен лимит пользователей.')+'<br/>'+ _t('Необходимо перезагрузить страницу.'), true, function() {
                                this.isRedirecting = true;
                                Ext.LockDocument.unlockAll(function() {
                                    window.location = '/login.php';
                                });
                            });
                        Ext.Msg.stopMessages = true;
                    }
                    break;
                case 'ip_access_deny':
                    Ext.connectSessionChecker.stop();
                    window.onbeforeunload = null;

                    this.showMessage(
                    _t('Внимание').toUpperCase() + '!!!'
                    , _t('Закрыт доступ в программу по IP.')+'<br />'+ _t('Обратитесь к администратору'), true, function() {
                        this.isRedirecting = true;
                        Ext.LockDocument.unlockAll(function() {
                            window.location.reload();
                        });
                    });
                    break;
                case 'connection_error_need_reload':
                    Ext.connectSessionChecker.stop();
                    window.onbeforeunload = null;

                    this.showMessage(
                        _t('Внимание').toUpperCase() + '!!!'
                        , _t('Не удалось проверить соединение с сервером.') +'<br />'+ _t('Страницу необходимо обновить.'), true, function() {
                            this.isRedirecting = true;
                            Ext.LockDocument.unlockAll(function() {
                                window.location.reload();
                            });
                        });
                    break;
            }
        } else if (params.GLOBAL_MESSAGE) {
            this.showMessage(_t('Глобальное сообщение'), params.GLOBAL_MESSAGE, !!params.GLOBAL_MESSAGE_ERROR);
        }
    }
    , renderStoreNumber: function(v, meta, rec) {
        if (GlobalProperties.get('numbering-store-enabled') == 1) {
            return rec.json.document_number || v;
        } else {
            return v
        }
    }
    ,showLoadingMask: function () {
        var body= Ext.WindowMgr.getActive();

        if (body) {
            body = body.el
        } else {

            body = Ext.getBody();
            if(!body){
                return;
            }
        }
        var isFinishing = false;
        body.mask(_t('Загрузка...')).dom.style.zIndex = '99999999999999';
        var fn =function(){
            if(!Ext.Ajax.transId){
                if (!isFinishing){
                    setTimeout(arguments.callee, 850);
                    isFinishing = true;
                } else {
                    body.unmask();
                }
            } else {
                isFinishing = false;
                setTimeout(arguments.callee,750);
            }
        };
        setTimeout( fn, 750 );
    },
    getChromeVersion: function () {
        let raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
        return raw ? parseInt(raw[2], 10) : false;
    },
    getPathAndFileName: function (path) {
        if (path.indexOf('/') == 0) {
            path = path.substring(1);
        }

        let name = path.split('/').pop();
        return {path: path, name: name};
    },
    renderGraphicTotalField: function (container, total, left, top) {
        if (Ext.isEmpty(container)) {
            console.error('renderGraphicTotalField: no container');
        }

        left = left || 19;
        top = top || 246;

        let id = 'ext4-graphics-total-div',
            div = document.createElement('div'),
            oldDiv = document. getElementById(id);

        if (oldDiv) {
            oldDiv.parentNode.removeChild(oldDiv);
        }

        div.id = id;

        div.style = [
            'left: ' + left + 'px;',
            'top: ' + top + 'px;',
            'width: 115px;',
            'height: 40px;',
            'position: absolute;',
            'padding: 6px;',
            'font-size: 14px;'
        ].join('');

        total = total || 0;
        div.textContent = _t('Итого:') + ' ' + total;

        container.el.dom.style.position = 'relative';
        container.el.appendChild(div);
    },
    showMessageRefreshPage: function () {
        Ext.Msg.show({
            title: _t('Очистка пользовательских настроек'),
            msg: _t(`Изменения вступят в силу после обновления страницы.<br />Обновить страницу?`),
            buttons: Ext.Msg.YESNO,
            icon: Ext.MessageBox.WARNING,
            minWidth: 450,
            fn: function (btn) {
                if (btn == 'yes') {
                    Ext.LockDocument.unlockAll(function () {
                        window.location.reload();
                    });

                }
            }
        });
    },
    setDefaultTitle: function () {
        if ('' != _CLINIC_TITLE) {
            document.title = _CLINIC_TITLE + _t(' - ВетМенеджер 2.0');
        }
    },
    getPhoneMaskObject: function (countryKey) {
        let masksObj = Ext.ux.phoneMask.maskItems;
        if (countryKey) {
            return (typeof masksObj[countryKey] != 'undefined')? masksObj[countryKey] : masksObj['other'];
        } else {
            return masksObj;
        }
    },
    findObjByMask: function (mask, key = '') {
        let masksObj = Ext.ux.phoneMask.maskItems;

        if (key) {
            return [masksObj[key]];
        }

        let masksArray = [];
        for (let key in masksObj) {
            if (masksObj[key].mask === mask) {
                masksObj[key].maskKey = key;
                masksArray.push(masksObj[key]);
            }
        }

        return masksArray;
    },
    getMinMaxPristavkaLengthBySameMasks: function (mask, key = '') {
        let minLen = [], maxLen = [], masksArray = this.findObjByMask(mask, key);

        for (let i = 0; i < masksArray.length; i++) {
            minLen.push(masksArray[i].minLength);
            maxLen.push(masksArray[i].maxLength);
        }

        let min = Math.min.apply(Math, minLen),
            max = Math.max.apply(Math, maxLen),
            pristavka = 0;

        for (let i = 0; i < masksArray.length; i++) {
            if (masksArray[i].maxLength === max) {
                pristavka = masksArray[i].prist;
                maskKey = masksArray[i].maskKey;
            }
        }

        return {prist: pristavka, minLength: min, maxLength: max, mask: mask, maskKey: maskKey}
    },
    setMinMaxPristavkaMaskToInput: function (inputEl, mask) {
        let key = mask.match(/^[a-zA-Z]*[0-9]*/gi)[0];
        mask = mask.replace(/^[a-zA-Z]*[0-9]*-/gi, '');

        let pristavka = Common.getMinMaxPristavkaLengthBySameMasks(mask, key);

        if (Ext.isFunction(inputEl.setLabelPristavka)) {
            inputEl.setLabelPristavka(pristavka.prist);
        }

        inputEl.maxLength = pristavka.maxLength;
        inputEl.minLength = pristavka.minLength;
        inputEl.isValid();
    },
    getUniqueMasks: function () {
        let listOfMasksWithCountryNames = [],
            masksObj = Ext.ux.phoneMask.maskItems;

        for (let key in masksObj) {
            if (!Ext.inArray(listOfMasksWithCountryNames, masksObj[key].mask)) {
                listOfMasksWithCountryNames.push({
                    mask: masksObj[key].mask,
                    countryName: masksObj[key].countryName,
                    key: key
                });
            }
        }

        return listOfMasksWithCountryNames;
    },
    getMaskDataForComboData: function () {
        let data = [], listOfMasks = this.getUniqueMasks();
        for (let i = 0; i < listOfMasks.length; i++) {
            let value = listOfMasks[i].mask,
                title = value.replace(/_/gi, 'x');

            data.push({
                value: listOfMasks[i].key + '-' + value,
                title: listOfMasks[i].countryName +' - '+ title,
                prist: listOfMasks[i].code
            });
        }

        return data;
    },
    getPetIconData: function () {
        return [
            {picture: '', url: "ui/desktop/images/s.gif", desc: _t('без иконки'), img: this.getPetImg('')},
            {picture: 'cat', url: this.getImageFullPath("cat.svg"), desc: _t('кошки'), img: this.getPetImg('cat')},
            {picture: 'dog', url: this.getImageFullPath("dog.svg"), desc: _t('собаки'), img: this.getPetImg('dog')},
            {picture: 'bird', url: this.getImageFullPath("bird.svg"), desc: _t('птицы'), img: this.getPetImg('bird')},
            {picture: 'horse', url: this.getImageFullPath("horse.svg"), desc: _t('лошади'), img: this.getPetImg('horse')},
            {picture: 'rabbit', url: this.getImageFullPath("rabbit.svg"), desc: _t('кролики'), img: this.getPetImg('rabbit')},
            {picture: 'reptile', url: this.getImageFullPath("reptile.svg"), desc: _t('рептилии'), img: this.getPetImg('reptile')},
            {picture: 'rodent', url: this.getImageFullPath("rodent.svg"), desc: _t('грызуны'), img: this.getPetImg('rodent')}
        ]
    },
    getPetImg: function (pictureName, width, height) {
        width = width || '20px';
        height = height || '20px';

        if (pictureName) {
            return '<img src="' + this.getImageFullPath(pictureName + '.svg') + '" width="' + width + '" height="' + height + '" />';
        } else {
            return '<img src="ui/desktop/images/s.gif" width="' + width + '" height="' + height + '" />';
        }
    },
    getGridSvgIcon: function (img, css, cls, attrs) {
        if (img === 'unknown' || Ext.isEmpty(img)) {
            img = 'question';
        }

        css = css || '';
        cls = cls || 'multi-grid-svg-icon';
        attrs = attrs || '';

        return this.getImageHTML(img + '.svg', css, `class="${cls}" ${attrs}`);
    },
    renderReceptionWriteChannel: function(channel) {

        switch(channel) {
            case 'vetmanager': return _t('Ветменеджер');
            case 'website': return _t('Онлайн Запись');
            default: return _t('Не известно');
        }
    },
    setNotAskFromResponseData: function (data) {
        let storageKey = 'doNotAskFor3Hours-' + _CURRENT_USER,
            localStorageNotAsk = Ext.decode(window.localStorage.getItem(storageKey));

        if (!Ext.isEmpty(localStorageNotAsk) && !Ext.isEmpty(localStorageNotAsk[1]) && localStorageNotAsk[1] === true) {
            if(data.is_error ){
                window.localStorage.setItem(storageKey, Ext.encode([0, true]));
            } else {
                this.setTimeNotAskToLocalStorage();
            }
        }
    },
    setTimeNotAskToLocalStorage: function () {
        let storageKey = 'doNotAskFor3Hours-' + _CURRENT_USER,
            localStorageNotAsk = Ext.decode(window.localStorage.getItem(storageKey)),
            time,
            today = new Date();

        today.setHours(today.getHours() + 3);
        time = localStorageNotAsk && localStorageNotAsk.length && localStorageNotAsk[0] > 0 ? localStorageNotAsk[0] : today.getTime();
        window.localStorage.setItem(storageKey, Ext.encode([time, true]));
    },
    removeLocalStorageNotAsk: function () {
        let i,
            keyName,
            keys = [],
            lengthLocalStorage = window.localStorage.length;

        for(i = 0; i < lengthLocalStorage; i++) {
            keyName = window.localStorage.key(i);
            if (keyName.search('doNotAskFor3Hours') != -1) {
                keys.push(keyName);
            }
        }

        for(i = 0; i < keys.length; i++) {
            window.localStorage.removeItem(keys[i]);
        }
    },
    smsCenterIsUsingSms: function(){
        let serviceIsEnabled = GlobalProperties.isServiceActive('smscenterIntegration'),
            isRegistered = parseInt(GlobalProperties.get('sms_center_is_registered', '0')),
            useSmsCenter = GlobalProperties.get('sms_center_use_sms', 'off');

        return (serviceIsEnabled && isRegistered > 0 && 'on' == useSmsCenter);
    },
    unisenderIsUsingSms: function(){
        let useSms = GlobalProperties.get('unisender_use_sms',  'off');
        return ('on' == useSms);
    },
    unisenderIsUsing: function(){
        let serviceIsEnabled = GlobalProperties.isServiceActive('unisenderIntegration'),
            apiNumber = GlobalProperties.get('unisender_api_number',  ''),
            email = GlobalProperties.get('unisender_email',  ''),
            phone = GlobalProperties.get('unisender_phone',  '');

        return (serviceIsEnabled && !Ext.isEmpty(apiNumber) && !Ext.isEmpty(email) && !Ext.isEmpty(phone));
    },
    getPhoneData: function(cellPhone) {
        cellPhone = cellPhone.replace(/[\(\)_-]/g, "");
        var fullPhoneMask = GlobalProperties.get('phone_mask', '(___)___-__-__');
        var unisenderPhonePristavkaDefault = GlobalProperties.get('unisender_phone_pristavka');
        var cellPhoneData = {
            'cell_phone':'',
            'phone_prefix':'',
            'unisender_phone_pristavka':''
        };
        var resultPhoneMask = fullPhoneMask.match(/(\(_{0,}\))?([_-]+)/) || [];
        var phonePrefixMask = (resultPhoneMask[1])? (resultPhoneMask[1]).replace(/[\(\)]/g, "") : '';
        var phoneMask = (resultPhoneMask[2])? (resultPhoneMask[2]).replace(/-/g, "") : '';
        var phonePrefixCountDigits = phonePrefixMask.length;
        var phoneCountDigits = phoneMask.length;

        var phoneIndexStart = cellPhone.length - phoneCountDigits;
        cellPhoneData.cell_phone = cellPhone.substring(phoneIndexStart);
        var phonePrefixIndexStart = cellPhone.length - phoneCountDigits - phonePrefixCountDigits;
        var phonePrefixIndexEnd = cellPhone.length - phoneCountDigits;
        cellPhoneData.phone_prefix = (phonePrefixCountDigits)? cellPhone.substring(phonePrefixIndexStart, phonePrefixIndexEnd) : '';
        var unisenderPhonePristavkaIndexStart = 0;
        var unisenderPhonePristavkaIndexEnd = phonePrefixIndexStart;
        cellPhoneData.unisender_phone_pristavka = (unisenderPhonePristavkaIndexEnd > 0)? cellPhone.substring(unisenderPhonePristavkaIndexStart, unisenderPhonePristavkaIndexEnd) : '';
        return cellPhoneData;
    }
    , valueToBool: function(v) {
        return (v === true || v === 'true' || v == '1' || String(v).toLowerCase() == 'on');
    }
    , copyToClipBoard: function (text) {
        navigator.clipboard.writeText(text).then(function() {
            Ext.MsgManager.alert(_t("Внимание"), _t("Текст скопирован в буфер обмена."));
        }, function() {
            Ext.MsgManager.alertError(_t("Ошибка"), _t("Не удалось скопировать"));
        });
    },
    getImageHTML: function (imageName, css, additionAttributes) {
        return '<img style="' + css + '" ' + additionAttributes + ' src="' + Common.getImageFullPath(imageName) + '" />';
    },
    getCurrentCallerID: function() {
        var err = '', callerLine, index;
        try { throw Error('') } catch(e) { err = e; }

        callerLine = err.stack.split("\n")[3];
        index = callerLine.indexOf("at ");
        return  callerLine.slice(index+2, callerLine.length);
    },
    showMedcardForPreview: function(medcardId, idPrefix) {
        var medicalcardsModule = VetManager.getModule('medicalcards', idPrefix);
        Ext.Ajax.request({
            url: 'ajax_medicalcards.php',
            scope: this,
            params: {'cmd': 'get_med_card', 'id': medcardId, 'mode': 'view'},
            success: function (r) {
                var data = Ext.decode(r.responseText);
                if (data.is_error == false) {
                    medicalcardsModule.petId = data.pet_id;
                    medicalcardsModule.clientId = data.client_id;
                    medicalcardsModule.medcard_id = medcardId;
                    medicalcardsModule.showMedcardPreview(medcardId);
                }
            }
        });
    },
    makeIFrameWithContent: function(content, container, cssFile) {
        var iFrame = document.createElement('iframe');
        iFrame.setAttribute('frameborder', '0');

        if (container) {
            container.appendChild(iFrame);
            iFrame.contentWindow.document.open();
            iFrame.contentWindow.document.write(content);
            iFrame.contentWindow.document.close();
            if (cssFile) {
                if (Ext.isArray(cssFile)) {
                    for (let itemCss of cssFile) {
                        this.addCssFileInIFrame(iFrame, itemCss);
                    }
                } else {
                    this.addCssFileInIFrame(iFrame, cssFile);
                }
            }
        } else {
            iFrame.src = 'data:text/html;charset=utf-8,' + encodeURI(content);
        }

        return iFrame;
    },
    addCssFileInIFrame: function (iFrame, cssFile) {
        var frm = iFrame.contentWindow.document;
        var otherhead = frm.getElementsByTagName("head")[0];
        var link = document.createElement("link");
        link.setAttribute("rel", "stylesheet");
        link.setAttribute("type", "text/css");
        link.setAttribute("href", cssFile);
        otherhead.appendChild(link);
    },
    isValidFIOData: function(data) {
        return Ext.isDefined(data.last_name) && Ext.isDefined(data.first_name) && Ext.isDefined(data.middle_name);
    },
    getFullFIO: function(data) {
        if (this.isValidFIOData(data)) {
            return (data.last_name + ' '
                + data.first_name + ' '
                + data.middle_name).trim()
        } else {
            console.error('Is not FIO data ', data);
            return '';
        }
    },
    getFullFIOAbbreviated: function (data) {
        var fio = '';
        if (data && data.last_name) {
            fio = fio + data.last_name + ' ';
        }
        if (data && data.last_name) {
            fio = fio + data.first_name.charAt(0).toUpperCase() + '. ';
        }
        if (data && data.last_name) {
            fio = fio + data.middle_name.charAt(0).toUpperCase() + '. ';
        }
        return fio;
    },
    checkAccessForModule: function(module, action) {
        if ('medcards' == module) {
            module = 'medicalcards';
        }

        if (action == 'admin') {
            action = 'administration';
        }

        let allAccess = Ext.app.Module.prototype.all_access;
        if (allAccess[module.toLowerCase()] != null) {
            return allAccess[module.toLowerCase()][action] === true;
        }
        return false;
    },
    checkAccessForModuleAndAlert: function(module, action) {
        let me = this;
        if (!me.checkAccessForModule(module, action)) {
            Common.showMessage(_t('Ошибка'), _t('Недостаточно прав доступа'), true);
            return false;
        } else {
            return true;
        }
    },
    copyToClipboard: function (html) {
        var text = $(html).text();
        Common.copyTextToClipboard(text);
    },
    copyTextToClipboard: function (text) {
        if (!navigator.clipboard) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                var isSuccessful = document.execCommand('copy');
                var msg = isSuccessful ? _t('Текст скопирован в память.') : _t('Ошибка копирования в буфер.');
                Common.showMessage('', msg, false);
            } catch (err) {
                console.error('Ошибка копирования в буфер: ', err);
            }
            document.body.removeChild(textArea);
            return;
        }
        navigator.clipboard.writeText(text).then(function () {
            Common.showMessage('', _t('Текст скопирован в память.'), false);
        }, function (err) {
            Common.showMessage('', _t('Ошибка копирования в буфер.'), false);
        });
    },
    roleAccessRenderer: function(value, meta, rec) {
        if (value === true) {
            meta.style+= 'background-color:#CCFFCC';
        } else if (value === false) {
            meta.style+= 'background-color:#FFCCCC';
        }
    },
    userAccessRenderer: function(value, meta, rec, row, col, store, view) {
        let needStyle = false, color;
        if (value === true) {
            needStyle = true;
            color = '#CCFFCC';
        } else if (value === false) {
            needStyle = true;
            color = '#FFCCCC';
        }

        if (needStyle) {
            let dataIndex = view.getGridColumns()[col].dataIndex;
            dataIndex = 'o_' + dataIndex.substr(2);
            if (rec.get(dataIndex)) {
                meta.style = `background-color:${color}`;
            } else {
                meta.style = `
                    background: repeating-linear-gradient(
                      135deg,
                      #FFF,
                      #FFF 5px,
                      ${color} 5px,
                      ${color} 10px
                    );
                `;
            }
        }
    },
    maskAllWindows: function() {
        Ext.WindowMgr.each(function(win) {
            win.el.mask();
        });
    },
    unmaskAllWindows: function() {
        Ext.WindowMgr.each(function(win) {
            win.el.unmask();
        });
    },
    mapArrayToInt: function(arr) {
        if (Ext.isArray(arr)) {
            return arr.map(item => parseInt(item));
        } else {
            return [];
        }
    },
    mapArrayToString: function(arr) {
        if (Ext.isArray(arr)) {
            return arr.map(item => item.toString());
        } else {
            return [];
        }
    },
    getComboItemTemplateWithQTip: function () {
        return '<tpl for="."><div ext:qclass="qtip-cls" ext:qtip="{title}" class="x-combo-list-item">{title}</div></tpl>';
    }
};

window.isGlobalAjaxMessage = function (response) {
    var separator = '%GLOBAL_MESSAGE_END%',
        responseText = (typeof response.responseText != "undefined") ? response.responseText : "",
        result = {
            isGlobal: false,
            message: '',
            responseText: ''
        };

    var index = responseText.indexOf(separator);

    if (index >= 0) {
        response.isGlobalMessage = true;
        result.isGlobal = true;
        result.message = Ext.decode(responseText.substr(0, index));
        result.responseText = response.responseText = responseText.substr(index + separator.length);
    }

    return result;
};

(function() {
    var originalRequest3 = Ext.Ajax.request;
    var globalCallBack = function(conn, resp, opts) {
        var globalCheck = isGlobalAjaxMessage(resp);

        if (globalCheck.isGlobal) {
            resp.isGlobalMessage = true;
            resp.responseText = globalCheck.responseText;
            Common.showGlobalMessage(globalCheck.message);
        }
    };
    Ext.Ajax.request = function () {
        try {
            arguments[0].headers = {
                'x-js-auth': _CLINIC_ID + ':' + _CURRENT_USER + ':' + _WORKSPACE + ':' + _SESSION_KEY
            };
            this.on('requestcomplete', globalCallBack, this, {single: true});
        } catch (e) {}

        return originalRequest3.apply(this, arguments);
    };
    var originalRequest4 = Ext4.Ajax.request;
    Ext4.Ajax.on('requestcomplete', globalCallBack, Ext4.Ajax);
    Ext4.Ajax.request = function () {
        try {
            arguments[0].headers = {
                'x-js-auth': _CLINIC_ID + ':' + _CURRENT_USER + ':' + _WORKSPACE + ':' + _SESSION_KEY
            };
        } catch (e) {}

        return originalRequest4.apply(this, arguments);
    };

    Ext.getBody().on('click', function (evt, target) {
        Common.copyToClipboard(target);
    }, null, {
        delegate: '.copy-to-clipboard'
    });
})();
var YandexMetrika = {
    sendAuthetificated: false,
    sendReachGoal: function () {
        try {
            if (!this.sendAuthetificated && ym) {
                var authetificated = _TARIFF_IS_FREE ? 'AuthetificatedInFree' : 'AuthetificatedInPaid';
                ym(
                    17106988,
                    'reachGoal',
                    authetificated,
                    {billing_id: _BILL_ACCOUNT_ID}
                );
                this.sendAuthetificated = true;
            }
        } catch (e) {
            console.log('Somethings went wrong with YM:  \n"' + e.message + '", \n if it hapens in dev environments it’s ok');
        }
    }
};
var ComboManual = {
    ADMISSION_TYPE: 'admission_type',
    ADMISSION_RESULT: 'admission_result',
    HOW_FIND_CLINIC: 'how_find_clinic',
    PET_COLORS: 'pet_colors',
    SERVICES_FOR_HOOKS: 'services_for_hooks',
    MANUFACTURERS: 'manufacturers',
    OPERATION_TYPES: 'Вид операций',
    INCOMING_DOCUMENT_TYPE: 'incoming_document_type',
    DIAGNOS_TYPES: 'diagnos_types',
    DIAGNOSES: 'diagnoses',
    CITIES: 'cities',
    STREETS: 'streets',
    ANIMAL_TYPES: 'animal_types',
    ANIMAL_SPECIES: 'animal_species',
    CLIENT_TYPES: 'client_types',
    UNITS: 'units',
    PRINTFORM_TYPES: 'printform_types',
    DISTRIBUTION_METHOD: 'distribution_method',
    VACCINATION_TYPE: 'vaccination_type',
    COMBINATION_TYPE: 'combination_type',
    APPOINTMENT_CHANNELS: 'appointment_channels',
    DOCUMENT_SCANING: 'Сканирование документа',
    MEDCARD_EXPORT: 'Экспорт медкарты',
    VOIP_CALL_TYPE: 'voip_call_type',
    VOIP_CALL_RESULT: 'voip_call_result',
    TEMPLATES_BONUS_PENALTY: 'templates_bonus_penalty',
    PRINTFORM_FORMAT: 'printform_format',
    TEMPLATE_TYPE: 'template_type',
    SEND_TYPE: 'send_type'
};
