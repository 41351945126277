Ext.ns('Tariff');
var pageLogin = {
    loginDlg: null,
    loginForm: null,
    checked: false,
    tariffDateEnd: false,
    tariffUsersLimit: false,
    loginDlgHeight: 285,
    showQRCode: true,
    redirectToBillMgrAfterLogin: false,
    hideHelpsPanel: false,
    init: function() {
        if (!this.checked){
            this.checkTariff();
            return;
        }
        if (!this.loginDlg) {
            this.initLoginDialog();
        }
        Common.removeLocalStorageNotAsk();
        this.loginDlg.show(Ext4.getBody());
        if (location.href.indexOf('?from-email') > 0) {
            VetManager.GlobalEvents.fireEvent('vm_goto_domain_from_mail');
        }
    },
    initLoginDialog: function() {
        var hideMask = function () {
            if (Ext.get('loading')) {
                Ext.get('loading').remove();
                Ext.fly('loading-mask').fadeOut({
                    remove:true
                });
            }
        };

        hideMask.defer(250);

        this.loginForm = Ext4.create('Ext4.form.Panel', {
            region: 'center',
            login: null,
            password: null,
            border: true,
            frame: true,
            header: {
                cls: ['vet-form-header', 'noborder', 'border-radius-10-t'],
                bodyCls: 'vet-form-header-body',
                titleAlign: 'center',
                hidden: true
            },
            title: ' ',
            cls: ['border-radius-8'],
            bodyCls: ['vet-panel-body'],
            padding: 3,
            style: {
                borderColor: '#B9C5D6',
                backgroundColor: '#DFE9F6',
                marginBottom: '7px'
            },
            url:'ajax_login.php',
            layout: {
                type: 'vbox',
                align: 'center',
                pack: 'center'
            },
            items:[
                {name: 'cmd', value: 'check_login', xtype: "hidden"},
                {name: 'skip_time_zone_check', value: false, xtype: "hidden"},
                {name: 'client_datetime', value: new Date().format('Y-m-d H:i:s'), xtype: "hidden"},
                {
                    xtype: 'textfield',
                    anchor: '100%',
                    width: 200,
                    emptyText: _t('Логин'),
                    name: 'f[login]',
                    cls: ['vet-form-field', 'vet-form-field-login'],
                    allowBlank: false,
                    blankText: _t('Обязательное поле'),
                    margin:'0 10 0 10',
                    enableKeyEvents: true,
                    listeners : {
                        keypress : function(fld, e) {
                            this.hideClinicCombo();
                            if (e.keyCode == e.ENTER){
                                this.focusPassField();
                            }
                        },
                        scope: this
                    }
                },
                {
                    xtype: 'textfield',
                    anchor: '100%',
                    width: 200,
                    emptyText: _t('Пароль'),
                    name: 'f[password]',
                    cls: ['vet-form-field', 'vet-form-field-pass'],
                    allowBlank: false,
                    blankText: _t('Обязательное поле'),
                    margin:'7 10 0 10',
                    inputType: 'password',
                    enableKeyEvents : true,
                    listeners : {
                        scope : this,
                        keypress : function(fld, e){
                            if (e.keyCode == e.ENTER){
                                this.sendLoginRequest(true);
                            }
                        }
                    }
                },
                {
                    xtype: 'combo',
                    anchor: '100%',
                    width: 200,
                    emptyText: _t('Выбор клиники'),
                    name: 'clinic',
                    cls: ['vet-form-field', 'vet-form-field-clinic'],
                    margin: 10,
                    valueField: 'id',
                    displayField: 'title',
                    queryMode: 'local',
                    allowBlank: true,
                    msgTarget: 'under',
                    hidden: true,
                    forceSelection:true,
                    store: {
                        xtype: 'store',
                        fields: ['id', 'title'],
                        data: []
                    }
                },
                {
                    xtype: 'button',
                    anchor: '100%',
                    width: 200,
                    text: _t('Войти'),
                    cls: ['vet-form-login-button', 'vet-form-button', 'border-radius-4'],
                    overCls: ['vet-form-button-over'],
                    pressedCls: ['vet-form-button-pressed'],
                    margin:'7 10 10 10',
                    scope: this,
                    handler: function() {
                        this.sendLoginRequest(true);
                    }
                },
                {
                    xtype: 'button',
                    anchor: '100%',
                    text: _t('Забыли пароль?'),
                    cls: ['vet-form-button-link'],
                    overCls: ['vet-form-button-link-light-over'],
                    margin: '10 10 0 10',
                    handler: this.remindPass,
                    scope: this
                }
            ]
        });

        this.helpsPanel = Ext4.create('Ext.window.Window', {
            autoWidth: true,
            height: 30,
            frame: true,
            layout: 'hbox',
            header: false,
            shadow: false,
            resizable: false,
            closable: false,
            maxWidth: 400,
            cls: ['noborder', 'nobackground', 'noshadow', 'login-form-bottom-navigation-links'],
            bodyCls: ['noborder', 'nobackground'],
            defaults: {
                xtype: 'button',
                cls: ['vet-form-button-link-light'],
                overCls: ['vet-form-button-link-light-over']
            },
            items:[
                {
                    text: _t('Справочный материал'),
                    href: 'https://help.vetmanager.cloud',
                    icon: 'ui/resources/images_new/info_gray.svg'
                }
            ]
        });

        this.QRCodeLogin = Ext4.create('Ext4.form.Panel', {
            region: 'east',
            width: 215,
            cls: ['border-radius-8'],
            bodyCls: ['vet-form-login-qrcode', 'vet-panel-body'],
            border: true,
            frame: true,
            padding: '0',
            style: {
                borderColor: '#B9C5D6',
                marginBottom: '7px',
                marginLeft: '20px',
            },
            items: [
                {
                    xtype:'panel',
                    border: false,
                    html: "<div class='view-qrcode' id='container-qr-code'>" +
                        "<img id='qr_code_auth_img' src=''>" +
                        "</div>"
                },
                {
                    xtype:'panel',
                    border: false,
                    items: [
                        {
                            xtype: 'panel',
                            border: false,
                            html: "<div style='font-size:0.85rem;padding:3px 7px;text-align:center;'>" + _t('QR-код для входа') + "</div>"
                        },
                        {
                            xtype: 'panel',
                            border: false,
                            layout: {
                                type: 'vbox',
                                align: 'center',
                                pack: 'center'
                            },
                            items: [
                                {
                                    xtype: 'button',
                                    anchor: '100%',
                                    text: _t('Как этим пользоваться?'),
                                    cls: ['vet-form-button-link'],
                                    overCls: ['vet-form-button-link-light-over'],
                                    margin: '10 10 0 10',
                                    handler: this.showDialogHowUseQRCodeAuth,
                                    scope: this
                                }
                            ]
                        }
                    ]
                }
            ],
            listeners: {
                afterrender: function (component, eOpts) {
                    Ext.AuthQRCodeCheck.createQRCode();
                }
            }
        });

        this.loginDlg = Ext4.create('Ext.window.Window', {
            width: 770,
            height: this.loginDlgHeight,
            padding: '0',
            frame: true,
            layout: 'border',
            header: false,
            shadow: false,
            resizable: false,
            closable: false,
            modal: false,
            cls: ['vet-window', 'border-radius-10'],
            bodyCls: ['vet-window-body', 'noborder', 'border-radius-8'],
            items: [
                {
                    width: 275,
                    region: 'west',
                    frame: true,
                    border: false,
                    padding: '0 20 0 0',
                    cls: ['noborder'],
                    bodyCls: ['noborder', 'vet-logo-big']
                },
                this.loginForm
            ],
            listeners: {
                afterrender: function(win){
                    var x = 0;
                    var y = 0;
                    x = (Ext4.getBody().getWidth() - 650) / 2;
                    y = (Ext4.getBody().getHeight() + win.getHeight()) / 2 + 50;
                    if (!this.hideHelpsPanel) {
                        this.helpsPanel.showAt(x, y);
                    }
                    var loginField = this.loginForm.getForm().findField('f[login]');
                    loginField.focus(true, 500);
                },
                scope: this
            }
        });
        if (this.showQRCode) {
            this.loginDlg.add(this.QRCodeLogin);
        }
        Ext.WorkspacesWin.on('setworkspace', function(){
            this.sendLoginRequest(false, true);
        }, this);

        Ext.DesktopPanel && Ext.DesktopPanel.on('setclinic', function(){
            this.sendLoginRequest(false, true);
        }, this);
    }
    , hideClinicCombo: function() {
        this.loginDlg.setHeight(this.loginDlgHeight);
        var combo = this.loginForm.getForm().findField('clinic');
        combo.hide();
        combo.allowBlank = true;
        this.loginForm.getHeader().removeCls('vet-form-header-error');
        this.loginForm.setTitle('');
    }
    , focusPassField : function(){
        if (this.loginForm){
            var passField = this.loginForm.getForm().findField('f[password]');
            passField.focus(true, 10);
        }
    }
    , sendLoginRequest: function(check, skip_time_zone_check) {
        var self = this;
        var entryParams = {
            check: check
            , skip: skip_time_zone_check
        };

        var loginForm = this.loginForm.getForm();
        if (!Ext.isEmpty(this.loginForm.login)) {
            this.loginForm.getForm().findField('f[login]').setValue(this.loginForm.login);
        } else {
            this.loginForm.login = this.loginForm.getForm().findField('f[login]').getValue();
        }
        if (!Ext.isEmpty(this.loginForm.password)) {
            this.loginForm.getForm().findField('f[password]').setValue(this.loginForm.password);
        } else {
            this.loginForm.password = this.loginForm.getForm().findField('f[password]').getValue();
        }
        skip_time_zone_check = (skip_time_zone_check == null) ? false : skip_time_zone_check;
        loginForm.findField('client_datetime').setValue((new Date().format('Y-m-d H:i:s')));
        loginForm.findField('cmd').setValue(check === true? 'check_login': 'login');
        loginForm.findField('skip_time_zone_check').setValue(skip_time_zone_check);

        if (loginForm.isValid()) {
            loginForm.submit();
            loginForm.on('actioncomplete', function(form, action) {
                if (action.result.is_error) {
                    this.loginForm.login = null;
                    this.loginForm.password = null;
                    if (action.result.wrong_time_zone) {
                        Ext4.MessageBox.confirm(
                            _t('Внимание')
                            , action.result.messages.join('<br/>')
                            , function(btn) {
                                if ('yes' === btn) {
                                    this.sendLoginRequest(check, true);
                                }
                            }
                            , this
                        );
                    } else if (action.result.need_change_password) {
                        this.showNewPassForm();
                    } else if (action.result.user_already_working) {
                        Ext4.Msg.show({
                            title: _t('Внимание')
                            , msg: _t('Пользователь с таким логином<br/>уже работает в системе!')
                            , buttonText: {
                                ok: _t('Ок')
                                , yes: _t('Все равно войти')
                            }
                            , icon: Ext4.MessageBox.WARNING
                            , scope: this
                            , fn: function (b) {
                                if ('yes' == b) {
                                    Ext.UserSessionChange._unlockSession({
                                        scope: this
                                        , other_user_id_to_lock: action.result.user_id
                                        , callback: function () {
                                            this.sendLoginRequest(entryParams.check, entryParams.skip);
                                        }
                                    });
                                }
                            }
                        });
                    } else {
                        if (!this.loginForm.getHeader().hasCls('vet-form-header-error')){
                            this.loginForm.getHeader().addCls('vet-form-header-error');
                        }
                        this.loginForm.getHeader().show();
                        this.loginForm.setTitle(action.result.messages[0]);
                        this.loginDlg.setHeight(this.loginDlgHeight+15);
                        this.focusPassField();
                    }
                } else {
                    if (this.redirectToBillMgrAfterLogin) {
                        Tariff.Common.openBillMgrWindow();
                        return;
                    }
                    if (action.result.clinics != null && action.result.clinics.length > 1) {
                        this.loginForm.getHeader().hide();
                        this.loginForm.getHeader().removeCls('vet-form-header-error');
                        this.loginForm.setTitle('');
                        this.loginDlg.setHeight(this.loginDlgHeight+15);
                        var combo = this.loginForm.getForm().findField('clinic');
                        combo.getStore().loadData(action.result.clinics);
                        combo.show();
                        this.loginForm.doLayout();
                        combo.allowBlank = false;
                        combo.expand();
                    } else {
                        this.loginDlg.hide();

                        if (check !== true) {
                            Ext.WorkspacesWin.redirect();
                        } else {
                            Ext.WorkspacesWin.show({
                                autoRedirect : false
                                , modal: false
                                , askSipOnLogin: action.result.ask_sip_on_login
                                , clinicId: parseInt(action.result.clinics[0].id)
                            });
                            if (action.result.is_need_show_wizard) {
                                Ext.WorkspacesWin.hide();
                                Ext.WorkspacesWin.redirect();
                            }
                        }
                    }
                }
            }, this, {single: true});
        }
    }
    , showNewPassForm: function() {
        this.newPassWin = Ext4.create('Ext.window.Window', {
            modal: true
            , closable: false
            , resizable: false
            , width:320
            , autoHeight: true
            , title: _t('Ввод нового пароля')
            , layout: 'fit'
            , items: [
                {
                    xtype:'form'
                    , frame: true
                    , id:'newPassForm'
                    , autoHeight: true
                    , baseCls: 'x-plain'
                    , labelWidth: 85
                    , padding: '5px'
                    , url:'ajax_login.php'
                    , items:[
                        {
                            xtype:'hidden'
                            , value: 'new_password'
                            , name:'cmd'
                        }, {
                            xtype: 'textfield'
                            , anchor: '97%'
                            , fieldLabel: _t('Пароль')
                            , name: 'password'
                            , allowBlank: false
                            , blankText: _t('Обязательное поле')
                            , inputType: 'password'
                        }, {
                            xtype: 'textfield'
                            , anchor: '97%'
                            , fieldLabel: _t('Пароль (повтор)')
                            , name: 'password_confirm'
                            , allowBlank: false
                            , blankText: _t('Обязательное поле')
                            , inputType: 'password'
                        }
                    ]
                }
            ]
            , buttons:[
                {
                    text: _t('Сохранить')
                    , scope: this
                    , handler:function() {
                        if (Ext4.getCmp('newPassForm').form.isValid()) {
                            var params = Ext4.getCmp('newPassForm').form.getFieldValues();
                            Ext4.Ajax.request({
                                url: 'ajax_access.php'
                                , success: function(result) {
                                    eval('result=' + result.responseText);
                                    if (result.is_error && result.messages) {
                                        Ext4.MessageBox.alert(_t('Внимание'), result.messages.join('<br />'));
                                    } else {
                                        Ext4.MessageBox.alert(_t('Внимание'), _t('Новый пароль сохранен, перезайдите в систему'));
                                        this.newPassWin.close();
                                    }
                                }
                                , scope: this
                                , params: params
                            });
                        }
                    }
                } , {
                    text: _t('Отмена')
                    , scope: this
                    , handler:function() {
                        this.newPassWin.close();
                    }
                }
            ]
            , buttonAlign: 'center'
        });
        this.newPassWin.show();
    }
    , remindPass: function() {
        var validator = function() {
            var form = Ext4.getCmp('remindPassForm');
            var login = form.form.findField('login');
            var email = form.form.findField('email');
            return ((login.getValue().length > 0) || (email.getValue().length > 0));
        };
        this.remindPassWnd = Ext4.create('Ext.window.Window', {
            modal: true
            , closable: true
            , resizable: false
            , width:320
            , height:135
            , title: _t('Восстановление пароля')
            , items: [
                {
                    xtype:'form'
                    , frame: true
                    , id:'remindPassForm'
                    , border:0
                    , bodyStyle: 'padding: 5px'
                    , baseCls: 'x-plain'
                    , labelWidth: 85
                    , padding: '15px'
                    , url:'ajax_login.php'
                    , items:[
                        {
                            xtype:'hidden'
                            , value: 'remind_pass'
                            , name:'cmd'
                        }, {
                            xtype:'textfield'
                            ,fieldLabel: 'Email'
                            , allowBlank: false
                            , anchor: '100%'
                            , name: 'email'
                            , regex: /^([\w-]+)(\.[\w-]+)*@([\w-]+\.){1,5}([A-Za-z]){2,4}$/
                            , invalidText: _t(`Это поле должно содержать адрес электронной почты в формате \\\"user@domain.com\\\" и состоять из латинских букв и цифр`)
                        }
                    ]
                }
            ]
            , buttons:[
                {
                    text: _t('Отправить')
                    , scope: this
                    , handler:function() {
                        var params = Ext4.getCmp('remindPassForm').form.getFieldValues();
                        Ext4.Ajax.request({
                            url: 'ajax_access.php'
                            , success: function(result) {
                                eval('result=' + result.responseText);
                                if (result.is_error && result.messages) {
                                    Ext4.MessageBox.alert(_t('Внимание'), result.messages.join('<br />'));
                                } else {
                                    Ext4.MessageBox.alert(_t('Внимание'), _t('Новый пароль выслан на Ваш email'));
                                    this.remindPassWnd.close()
                                }
                            }
                            , scope: this
                            , params: params
                        });
                    }
                } , {
                    text: _t('Отмена')
                    , scope: this
                    , handler:function() {
                        this.remindPassWnd.close();
                    }
                }
            ]
            , buttonAlign: 'center'
        });
        this.remindPassWnd.show();
    }
    , checkTariff: function(outerObj) {
       Ext.Ajax.request({
            url: 'ajax_access.php'
            , scope: this
            , params: {cmd: 'check_tariff'}
            , success: function(r){
                var data = Ext.decode(r.responseText);

                if (data.is_error == false) {
                    this.checked = true;
                    this.init();
                } else {
                    window.location = '/tariff.php';
                }
            }
        });
    }
    , showPreEndTariffMessage: function(daysLeft) {
        daysLeft = parseInt(daysLeft);
        if (isNaN(daysLeft) || [0, 3, 7].indexOf(daysLeft) < 0 || Ext.util.Cookies.get('is_show_notify_' + _CURRENT_USER) == 1) {
            return;
        } else {
            var text = _t('Заканчивается срок действия тарифа') + '.<br />';
            switch (daysLeft) {
                case 7:
                    text += _t('До окончания тарифа осталось 7 дней');
                    break;
                case 3:
                    text += _t('До окончания тарифа осталось 3 дня');
                    break;
                case 0:
                    text += _t('Сегодня последний день действия тарифа');
                    break;
            }
            Common.showMessage(_t('Внимание'), text, true, function() {
                Ext.util.Cookies.set('is_show_notify_' + _CURRENT_USER, 1, (new Date()).add(Date.DAY, 1));
            });
        }
    }
    , showDialogHowUseQRCodeAuth: function() {
        var imgPath = Common.getImageFullPath("brand_qr_code_auth_explanation.gif");
        Ext4.create('Ext.window.Window', {
            modal: true
            , title: _t('Использование QR-кода для входа')
            , cls: ['dialog-how-use-qrcode']
            , autoDestroy: true
            , autoShow: false
            , closable: true
            , resizable: false
            , width: 400
            , height: 600
            , style: {
                boxSizing: 'border-box'
            }
            , items: [
                {
                    xtype:'panel'
                    , border: false
                    , html: "<div class='' >" +
                        "<p style='text-align: center;margin: 7px 0px;'>" + _t('Откройте приложение Vetmanager Mobile:') + "</p>" +
                        "<img src='' id='img-how-use-qrcode-auth' height='550' style='display: block;margin: 0px auto;'>" +
                        "</div>"
                }
            ]
            , listeners : {
                scope : this
                , afterrender : function() {
                    var imgEl = document.querySelector('#img-how-use-qrcode-auth');
                    imgEl.setAttribute('src', imgPath);
                }
            }
        }).show();
    }
    , setConfig: function(config) {
        var me = this;
        me = Object.assign(me, config);
    }
};