Ext.ns('Ext.ux');

var vetBtnKP = function(e, id) {
    var b = Ext.getCmp(id);
    if (b.isFocused && b.hidden == false && b.disabled == false && e.keyCode == 13) {
        b.handler.call(b.scope);
    }
};

var vetBtnBlur = function(id) {
    var el = Ext.getCmp(id);
    if (el) {
        el.isFocused = false;
    }
};

var vetBtnFocus = function(id) {
    var el = Ext.getCmp(id);
    if (el) {
        el.isFocused = true;
    }
};

Ext.ux.VetButton = Ext.extend(Ext.Button, {
    template: new Ext.Template(
        [
            '<div class="vet-button vet-text-button" style="background:{background} !important;">'
                , '<span  id="{id}" style="color:{color};" onfocus="vetBtnFocus(this.id);" onblur="vetBtnBlur(this.id);" tabindex="0" onkeypress="vetBtnKP(event, this.id);" type="button">{text}</span>'
                , '<span  class="{iconClassRight}" ></span>'
            , '</div>'
        ]
        , {compiled: true}
    )
    , buttonSelector: 'span:first-child'
    , icon: ''
    , iconCls: ''
    , iconClassRight: ''
    , isFocused: false
    , scale: 'small'
    , module: null
    , handler: Ext.emptyFn
    , color: 'black'
    , xtype: 'vetbutton'
    , microDisable: true
    , initComponent: function() {
        Ext.Button.superclass.initComponent.call(this, arguments);
    }
    , getTemplateArgs : function(){
        var innerWidth = this.width ? (this.width - 18) + 'px' : 'auto',
            innerHeight = this.height ? (this.height - 8) + 'px' : 'auto',
            margins = this.margins ? this.margins: '0',
            backgroundFrom = this.backgroundFrom ? this.backgroundFrom : this.background,
            backgroundTo = this.backgroundTo ? this.backgroundTo : this.background,
            background = !Ext.isEmpty(backgroundFrom) && !Ext.isEmpty(backgroundTo)
                ? '-webkit-gradient(linear,left top, left bottom, from(' + backgroundFrom + '), to(' + backgroundTo + '))'
                : '',
            color = this.color + (this.color != 'black' ? ' !important' : '');
        return {
            id: this.id
            , text: this.text
            , innerWidth: innerWidth
            , innerHeight: innerHeight
            , margins: margins
            , color: color
            , background: background
            , iconClassRight: this.iconClassRight
        };
    }
});

Ext.reg('vetbutton', Ext.ux.VetButton);

Ext.ux.VetIconButton = Ext.extend(Ext.ux.VetButton, {
    icon: ''
    , template: new Ext.Template(
        [
            '<div class="vet-button vet-icon-button {advancedClass}">'
               
                        , '<img id="{id}" src="ui/desktop/images/s.gif" style="width:{imageWidth};height:{imageHeight};margin-top:{marginTop}; background-repeat: no-repeat; background-position: {backgroundPosition}; {additionalImageStyles}" />'
                    
                
            , '</div>'
        ]
        , {compiled: true}
    )
    , buttonSelector: 'img:first-child'
    , xtype: 'veticonbutton'
    , initComponent: function() {
        Ext.ux.VetButton.superclass.initComponent.call(this, arguments);
    }
   , getTemplateArgs : function(){
        

        return {
            id: this.id
            , icon: this.icon
            , advancedClass: this.disableByIcon ? 'vet-icon-button-disable-by-icon' : ''
        };
    }
});

Ext.reg('veticonbutton', Ext.ux.VetIconButton);

Ext.ux.VetIconTextButton = Ext.extend(Ext.ux.VetIconButton, {
   text: ''
   , template: new Ext.Template(
        [
            '<div class="vet-button vet-icon-text-button" >'
                
                        , '<img id="{id}" src="{icon}" />'
                        , '<span id="{id}" onfocus="vetBtnFocus(this.id);" onblur="vetBtnBlur(this.id);" tabindex="0" onkeypress="vetBtnKP(event, this.id);" type="button">{text}</span>'
                
            , '</div>'
        ]
        , {compiled: true}
    )
    , xtype: 'veticontextbutton'
    , initComponent: function() {
        Ext.ux.VetIconButton.superclass.initComponent.call(this, arguments);
    }
   , getTemplateArgs : function(){
       var data = Ext.ux.VetIconTextButton.superclass.getTemplateArgs.apply(this, arguments);
       data.text = this.text;
       data.innerWidth =  this.width ? (this.width - 18) + 'px' : 'auto';
       return data;
    }
});
Ext.reg('veticontextbutton', Ext.ux.VetIconTextButton);


Ext.ux.VetBackButton = Ext.extend(Ext.ux.VetIconButton, {
    icon: 'ui/resources/images_new/back.svg'
    , xtype: 'vetbackbutton'
    , tooltip: _t('Назад')
});

Ext.reg('vetbackbutton', Ext.ux.VetBackButton);

Ext.ux.VetImportButton = Ext.extend(Ext.ux.VetIconTextButton, {
    icon: 'ui/resources/images_new/bulk-import.svg'
    , xtype: 'vetimportbutton'
    , tooltip: _t('Импорт')
    , text: _t('Импорт')
});
Ext.reg('vetimportbutton', Ext.ux.VetIconTextButton);

Ext.ux.VetSaveButton = Ext.extend(Ext.ux.VetIconTextButton, {
    icon: 'ui/resources/images_new/save.svg'
    , xtype: 'vetsavebutton'
    , tooltip: _t('Сохранить')
    , text: _t('Сохранить')
});
Ext.reg('vetsavebutton', Ext.ux.VetSaveButton);

Ext.ux.VetEditIconButton = Ext.extend(Ext.ux.VetIconButton, {
    icon: 'ui/resources/images_new/edit.svg'
    , xtype: 'vetediticonbutton'
    , tooltip: _t('Редактировать')
});
Ext.reg('vetediticonbutton', Ext.ux.VetEditIconButton);

Ext.ux.VetDeleteIconButton = Ext.extend(Ext.ux.VetIconButton, {
    icon: 'ui/resources/images_new/delete.svg'
    , xtype: 'vetdeleteiconbutton'
    , tooltip: _t('Удалить')
});
Ext.reg('vetdeleteiconbutton', Ext.ux.VetDeleteIconButton);


Ext.ux.VetAddIconButton = Ext.extend(Ext.ux.VetIconButton, {
    icon: 'ui/resources/images_new/add.svg'
    , xtype: 'vetaddiconbutton'
    , tooltip: _t('Добавить')
});
Ext.reg('vetaddiconbutton', Ext.ux.VetAddIconButton);


Ext.ux.VetSaveIconButton = Ext.extend(Ext.ux.VetIconButton, {
    icon: 'ui/resources/images_new/save.svg'
    , xtype: 'vetsaveiconbutton'
    , tooltip: _t('Редактировать')
});
Ext.reg('vetsaveiconbutton', Ext.ux.VetSaveIconButton);


Ext.ux.VetButtonRedCircle = Ext.extend(Ext.ux.VetButton, {
    icon: '',
    template: new Ext.Template(
        [
            '<div class="VP5otc-HT6HAf J-J5-Ji">',
                '<div class="J-Zh-I J-J5-Ji W6eDmd L3 redbtngradient" style="width:{innerWidth}; border-radius: 15px; height: {innerHeight}">',
                    '<div class="QkhFhe">',
                        '<span style="line-height:{innerHeight}; color: white;" id="{id}" class="J-J5-Ji nwE2fc" onfocus="vetBtnFocus(this.id);" onblur="vetBtnBlur(this.id);" tabindex="0" onkeypress="vetBtnKP(event, this.id);" type="button">{text}</span>',
                    '</div>',
                '</div>',
            '</div>'
        ],
        {compiled: true}
    )
});