/*
* Костыль для клиентов, у которых newrelic не может отправить
* информацию о событии и съедает всю память до креша браузера.
* Костыль принудительно очищает накопленные события каждые 3 минуты,
* если массив событий не очищается.
* */

setInterval(
    function () {
        if (window.NREUM?.ee?.backlog?.feature?.length >= 3000) {
            window.NREUM.ee.backlog.feature = [];
        }
        if (window.NREUM?.ee?.backlog?.spa?.length >= 3000) {
            window.NREUM.ee.backlog.spa = [];
        }
        if (window.NREUM?.ee?.backlog?.session_trace?.length >= 3000) {
            window.NREUM.ee.backlog.session_trace = [];
        }
    },
    1000 * 60
);