Ext.AuthQRCodeCheck = new function() {
    this.isStarted = false, this.settings = {}, this.intervalId = null;
    this.defaultsSettings = {
        'secondsCountdown':5,
        'authKey':''
    };

    this.initCountdownCheckAuthQRCode = function(importOptions) {
        this.settings = Object.assign({}, this.defaultsSettings, importOptions);
        this.settings.secondsCountdown = parseInt(this.settings.secondsCountdown * 1000);
        setTimeout(function() {
            Ext.AuthQRCodeCheck.start();
        }.bind(this), this.settings.secondsCountdown);
    };

    this.start = function() {
        if (this.isStarted) {
            return false;
        } else {
            this.isStarted = true;
            this.intervalId = setInterval(function() {
                var params = {
                    'cmd':'check',
                    'auth_key':this.settings.authKey
                };
                this.sendRequest(params);
            }.bind(this), this.settings.secondsCountdown);
        }
    };
    this.finish = function() {
        clearInterval(this.intervalId);
        this.isStarted = false;
    };

    this.sendRequest = function(params) {
        Ext.Ajax.request({
            url: 'ajax_qr_code_auth.php'
            , scope: this
            , timeout: 3000
            , params: params
            , callback: function(conn, success, response) {
                var responseData = {};
                if (success) {
                    responseData = Ext.decode(response.responseText);
                    if (responseData) {
                        if (true == responseData.reload_auth_code
                            && responseData.auth_key
                            && responseData.auth_key.length > 0
                            && responseData.img_data
                            && responseData.img_data.length > 0
                        ) {
                            this.settings.authKey = responseData.auth_key;
                            this.refreshQRCodeImg(responseData.img_data);
                        } else if (true == responseData.redirect_page) {
                            pageLogin.loginDlg.hide();
                            Ext.WorkspacesWin.show({
                                autoRedirect : true
                                , modal: false
                                , isMobileAuth: true
                            });
                        } else if (true == responseData.user_already_working) {
                            Ext.AuthQRCodeCheck.finish();
                            Ext.UserSessionChange._unlockSession({
                                scope: this
                                ,other_user_id_to_lock: responseData.user_id
                                , callback: function () {
                                    var params = {
                                        'cmd':'check',
                                        'auth_key':this.settings.authKey
                                    };
                                    this.sendRequest(params);
                                }
                            });
                        }
                    }
                }
            }
        });
    };

    this.refreshQRCodeImg = function(img_data) {
        if (document.querySelector('#qr_code_auth_img')) {
            document.querySelector('#qr_code_auth_img').remove();
            if (document.querySelector('#container-qr-code') && typeof img_data != 'undefined' && img_data.length > 0) {
                document.querySelector('#container-qr-code').innerHTML = "<img id='qr_code_auth_img' src='"+img_data+"'>";
            }
        }
    };

    this.createQRCode = function() {
        Ext.Ajax.request({
            url: 'ajax_qr_code_auth.php'
            , params: {'cmd':'create'}
            , scope: this
            , callback: function(conn, success, response) {
                var responseData = {};
                if (success) {
                    responseData = Ext.decode(response.responseText);
                    if (responseData.img_data && responseData.auth_key) {
                        Ext.AuthQRCodeCheck.refreshQRCodeImg(responseData.img_data);
                        Ext.AuthQRCodeCheck.initCountdownCheckAuthQRCode({
                            'secondsCountdown':5,
                            'authKey':responseData.auth_key
                        });
                    }
                }
            }
        });
    };
};