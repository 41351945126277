Ext.connectSessionChecker = {
    interval: 60000,
    inited: false,
    timeoutConnect: 30000,
    runner: new Ext.util.TaskRunner(),
    tariffChecker: new Ext.util.TaskRunner(),
    endingGoodsChecker: new Ext.util.TaskRunner(),
    usersTotal: 0,
    retryCount: 0,
    retryCountMax: 1,
    start: function() {
        if (location.pathname == '/tariff.php' || location.pathname == '/login.php') {
            console.log('Ignore connection checking');
            return;
        }
        let task = {
            run: this.checker,
            interval: this.interval * 2, // Оставить 2 минуты. После 3‑х минут чистится LockDocument
            scope: this
        };

        let tariffTask = {
            run: this.runCheckTariff,
            interval: 1000 * 60 * 10,
            scope: this
        };

        let endingGoodsTask = null,
            timeout = 0;


        if (typeof(_NOTIFICATION_FREQUENCY) !== 'undefined') {
            switch(_NOTIFICATION_FREQUENCY) {
                case 'once_on_entrance':
                    timeout = 0;
                    break;
                case 'once_in_half_hour':
                    timeout = 1000 * 60 * 30;
                    break;
                case 'once_in_hour':
                    timeout = 1000 * 60 * 60;
                    break;
                case 'once_in_day':
                    timeout = 1000 * 60 * 60 * 24;
                    break;
            }

            //debugger;
            if (timeout == 0) {
                this.checkEndingGoodsAndExpireDateNotify();
            } else {
                endingGoodsTask = {
                    run: this.checkEndingGoodsAndExpireDateNotify,
                    interval: timeout,
                    scope: this
                };
            }
        }
        this.firstStart();
        this.runner.start(task);
        this.tariffChecker.start(tariffTask);
        if (endingGoodsTask) {
            this.endingGoodsChecker.start(endingGoodsTask);
        }
    },
    firstStart: function(){
        if (!this.inited) {
            let self = this,
                TIME_WAIT_FOR_START  = Math.floor((Math.random() * 5) + 1) * 60 * 1000; //1 - 5 min
            setTimeout(function(){
                self.checkTariff(Ext.emptyFn, true);
            }, TIME_WAIT_FOR_START);
            this.inited = true;
        }
    },
    stop: function() {
        console.log('connectSessionChecker stoped');
        this.runner.stopAll();
    },
    runCheckTariff: function(){
        try{
            if (navigator.onLine){
                Ext.Ajax.request({
                    url: 'ajax_access.php',
                    scope: pageLogin,
                    params: {cmd: 'check_tariff'},
                    success: function(r){
                        let data = Ext.decode(r.responseText);
                        if (data.is_error == false) {
                            this.checked = true;
                            if (!window._CURRENT_USER){
                                this.init();
                            }
                            if (data.is_date_pre_end) {
                                VetManager.GlobalEvents.fireEvent('vm_alert_tariff');
                                this.showPreEndTariffMessage(data.days_left);
                            }
                        } else {
                            this.tariffDateEnd = data.is_date_end;
                            this.tariffUsersLimit = data.is_limit_users;
                            if (this.tariffDateEnd){
                                this.checked = false;
                                if(data.tariff_data[0].is_free == 1){
                                    VetManager.GlobalEvents.fireEvent('vm_trial_end');
                                    Tariff.Common.limitUserErrorTrial(this);
                                }else{
                                    VetManager.GlobalEvents.fireEvent('vm_tariff_end');
                                    window.location.href = 'tariff.php';
                                }
                            } else {
                                this.checked = true;
                                if (!window._CURRENT_USER){
                                    this.init();
                                }
                            }
                            if (this.tariffUsersLimit) {
                                //Tariff.Common.limitUserError();
                                Tariff.Common.limitUserErrorWin(this, data.is_limit_users, data.users_registered);
                            }
                        }
                    }
                });
                Ext.defer(this.checkTariff, 21600000, this); //каждые 6 часов
            }else{
                console.log('request');
                Ext.defer(this.requestTariff, 200000, this);
            }
        }catch(e){}
    },
    checker: function() {
        let params = {cmd: 'checkSessionAndConnect', session_key: _SESSION_KEY};

        if (_IS_ACTIVITY_SUSPENDED && !_IS_SESSION_LOCKED) {
            params.SUPERAJAX = 1;
        }

        Ext.Ajax.request({
            url: 'ajax_login.php',
            params: params,
            timeout: this.timeoutConnect,
            scope: this,
            success: this.checkSessionLive,
            failure: () => Ext.lostConnect.show()
        });
    },
    isDifferentVersions: function(file, cur) {
        file = file || '';
        cur = cur || '';
        file = Ext.util.Format.trim(file);
        cur = Ext.util.Format.trim(cur);
        return (file != '' && cur != '' && file != cur);
    },
    checkSessionLive: function(response) {
        let me = this,
            responseObject = Ext.decode(response.responseText);

        if (!window['VetManager']){
            return;
        }

        if (!responseObject.isSessionLive) {
            if (me.retryCount < me.retryCountMax) {
                me.retryCount++;
                Ext.MessageBox.show({
                    title: _t('ВНИМАНИЕ!!!'),
                    msg: _t('Не удалось проверить соединение с сервером.') + '<br />' + _t('Повторно проверить соединение.'),
                    buttons: Ext.Msg.OK,
                    minWidth: 280,
                    icon: Ext.MessageBox.ERROR,
                    fn: () => {
                        me.checker()
                    }
                });
                return;
            } else {
                window.onbeforeunload = null;

                Common.showGlobalMessage({
                    GLOBAL_MESSAGE_TYPE: 'connection_error_need_reload'
                });
            }
            return;
        }

        if (responseObject.reload_page != null && responseObject.reload_page == 1) {
            window.location.reload();
        }

        VetManager.eventManager.fireEvent('vetmanager_session_live');

        me.checkGlobalMessages(responseObject.global_messages);

        if (me.isDifferentVersions(responseObject.version, Ext.VetmanagerVersion)) {
            Ext.MsgManager.alert(
                _t('Системное сообщение'),
                _t('Новая версия программы')+': "' + responseObject.version + '" '+_t('обновите страницу')
            );
            Ext.CountdownVersionVetmanager.initCountdownVersion();
        }

        Ext.lostConnect.close();

        me.retryCount = 0;

        me.checkChatNewMessages(parseInt(responseObject.unread_messages));
        _LAST_UNREAD_MESSAGE_ID = parseInt(responseObject.private_messages);
        _LAST_PRIVATE_MESSAGE_ID = parseInt(responseObject.private_messages);
        _SMENA_MAIN_USER = responseObject.smena_main_user;
        me.checkEndScheduleTime(responseObject.in_schedule, responseObject.schedule_end_alert);
    },
    checkChatNewMessages: function (unreadMsg) {
        let me = this;
        if (unreadMsg !== -1 && !_IS_CHAT_OPEN) {
            if (unreadMsg !== -1 && _LAST_UNREAD_MESSAGE_ID != unreadMsg) {
                Ext.MsgManager.alert(_t('Сообщение чата'), _t('У вас есть непрочитанные сообщения'));
                me.setFavicon(2);

                let player = document.getElementById('chat-message-player');

                if (player) {
                    player.pause();
                    player.currentTime = 0;
                    player.play();
                }
            }
        } else {
            me.setFavicon(1);
        }
    },
    setFavicon: function(param) {
        let head = document.getElementsByTagName("head")[0];
        let links = head.getElementsByTagName("link");

        for (let i = 0; i < links.length; i++) {
            let lnk = links[i];
            if (lnk.rel=="shortcut icon") {
                head.removeChild(lnk);
                break;
            }
        }

        let link = document.createElement("link");

        if (param == 1) {
            link.setAttribute("href","favicon.ico");
        } else if (param == 2) {
            link.setAttribute("href","favicon2.ico");
        }

        link.setAttribute("type","image/x-icon");
        link.setAttribute("rel","shortcut icon");
        head.appendChild(link);
    },
    dropSession: function (successFn) {
        Ext.Ajax.request({
            url: 'ajax_login.php',
            params: {cmd: 'logout', session_key: _SESSION_KEY},
            success: function () {
                if (successFn && Ext.isFunction(successFn)) {
                    successFn.call(this);
                }
            }
        });
    },
    checkGlobalMessages: function(data) {
        let cm = VetManager.getModule('global_messages');
        cm.route('GlobalMessages.Controller.showGlobalMessageBox', data);
    },
    requestTariff: function(
        isOnline,
        isFree,
        limitUsers,
        tariffValidUntil,
        tariffBlocked,
        lastPaidPeriod,
        tariffName,
        prolongation,
        dateRegister,
        addons,
        billingData,
        callback
    ){
        let params = {
            is_online: (isOnline) ? 1 : 0,
            is_free: isFree,
            limit_users: limitUsers,
            tariff_valid_until: tariffValidUntil,
            tariff_blocked: tariffBlocked ? 1 : 0,
            last_paid_period: lastPaidPeriod,
            tariff_name: tariffName,
            prolongation: prolongation ? Ext.encode(prolongation) : null,
            cmd: 'request_tariff',
            date_register: dateRegister,
            addons: addons,
            billingData: billingData
        };

        Ext.defer(function(){
            Ext.Ajax.request({
                url: 'ajax_access.php',
                timeout: 3000,
                params: params,
                callback: function(conn, success, response) {
                    let data = {};
                    if (success) {
                        data = Ext.decode(response.responseText);
                    }
                    if (Ext.isFunction(callback)) {
                        callback.call(this, data);
                    }
                }
            });
        }, 250, this);
    },
    checkTariff: function(callback, force){
        let domainName = _DOMAIN_NAME;

        if (!Ext.isEmpty(domainName) && navigator.onLine) {
            var store = Ext4.create('Ext.data.Store', {
                fields: [
                    'tariff_is_free',
                    'tariff_limit_users',
                    'tariff_valid_until',
                    'last_paid_period',
                    'tariff_name',
                    'tariff_name_ru',
                    'prolongation',
                    'date_register',
                    'tariff_is_blocked',
                    'country_id',
                    'addons',
                    'account_id'
                ],
                proxy: {
                    type: 'jsonp',
                    url : Tariff.Common.billing.url + '/user/' + Tariff.Common.billing.auth + '/' + domainName,
                    callbackKey: 'callback',
                    extraParams: {
                        domain_name: domainName
                    },
                    autoAppendParams: false,
                    timeout: 3000,
                    listeners: {
                        scope: this
                    }
                }
            });

            store.on('load', function(store, recs) {
                if (recs && recs.length > 0) {
                    let rec = recs[0];
                    let isFree = rec.get('tariff_is_free');
                    let limitUsers = rec.get('tariff_limit_users');
                    let tariffValidUntil = rec.get('tariff_valid_until');
                    let tariffBlocked = rec.get('tariff_is_blocked');
                    let lastPaidPeriod = rec.get('last_paid_period');
                    let tariffName = rec.get('tariff_name_ru');
                    let prolongation = rec.get('prolongation');
                    let dateRegister = rec.get('date_register');
                    let addons = Ext.encode(rec.get('addons'));
                    let billingData = Ext.encode(rec.raw);

                    this.requestTariff(
                        true,
                        isFree,
                        limitUsers,
                        tariffValidUntil,
                        tariffBlocked,
                        lastPaidPeriod,
                        tariffName,
                        prolongation,
                        dateRegister,
                        addons,
                        billingData,
                        callback
                    );
                }
            }, this);

            store.load();
        }

    },
    checkEndScheduleTime: function(in_schedule, schedule_end_alert) {
        if (!in_schedule) {
            Ext.MsgManager.alertError(_t('Внимание'), _t(`Нельзя находится в программе по завершению рабочего графика.<br/>Обратитесь к администратору`));
            Ext.logoutFn();
        } else if (in_schedule && schedule_end_alert) {
            Ext.MsgManager.alertError(_t('Внимание'), _t(`Нельзя находится в программе по завершению рабочего графика.<br/>Обратитесь к администратору`));
        }
    },

    checkEndingGoodsNotify: function (withoutPopup) {
        Ext.Ajax.request({
            url: 'ajax_ending_goods.php',
            params: {
                cmd: 'check_for_ending_goods'
            },
            scope: this,
            success: function (response) {
                let data = Ext.decode(response.responseText),
                    msg = this._setGoodsCheckResponseStateAndReturnMessage("end_goods", data);

                if (withoutPopup) {
                    VMTools.checkEndGoods();
                } else {
                    this.endingGoodsCheckMsgDialog(msg);
                }
            }
        });
    },
    checkWrongSmsMessageSenderNotify: function (msg) {
        let me = this;

        Ext.Ajax.request({
            url: 'ajax_message_sender.php',
            params: {
                cmd: 'check_not_working_senders'
            },
            scope: this,
            success: function (response) {
                let data = Ext.decode(response.responseText);

                if (data.is_notify) {
                    let messageSenderErrors = me._setGoodsCheckResponseStateAndReturnMessage("senders_with_error", data);

                    me.updateMessageSenderDataFromCookies(data);

                    if (!Ext.isEmpty(messageSenderErrors) && data.data.length > 0) {
                        let sendersLinks = [];

                        for (let i = 0; i < data.data.length; i++) {

                            let module = 'message_sender_fast_messages';

                            if (data.data[i].send_method == 'action') {
                                module = 'message_sender_by_actions';
                            } else if (data.data[i].send_method == 'remind') {
                                module = 'message_sender_reminder';
                            }

                            let link = "VetManager.getModule('" + module + "').showReport(" + data.data[i].id + ", " + _CLINIC_ID + ");";

                            sendersLinks.push('<a className="link" href="javascript:' + link + '">' + data.data[i].title + '</a>');
                        }

                        messageSenderErrors = messageSenderErrors + ' ' + sendersLinks.join(', ');


                        msg.push(messageSenderErrors);
                    }
                }
                this.endingGoodsCheckMsgDialog(msg, data);
            }
        });
    },
    checkEndExpirationGoodsNotify: function (msg, withoutPopup, callback) {
        Ext.Ajax.request({
            url: 'ajax_end_expiration_goods.php',
            params: {
                cmd: 'check_for_end_expiration_goods'
            },
            scope: this,
            success: function (response) {
                let data = Ext.decode(response.responseText);
                msg = (msg) ? [msg] : [];

                let message = this._setGoodsCheckResponseStateAndReturnMessage("exp_shelf", data);
                if (!Ext.isEmpty(message)) {
                    msg.push(message);
                }

                if (withoutPopup) {
                    VMTools.checkShelf();
                } else {
                    if (callback) {
                        callback.call(this, msg);
                    }
                }
            }
        });
    },

    checkEndingGoodsAndExpireDateNotify: function () {
        let me = this,
            whereHideMsgIdPanels = ["mainSettingsWindow"],
            isNeedCheck = true;

        Ext.each(whereHideMsgIdPanels, function (id) {
            let element = Ext.get(id);
            if (element) {
                isNeedCheck = false;
            }
        });

        if (isNeedCheck) {
            Ext.Ajax.request({
                url: 'ajax_ending_goods.php',
                params: {
                    cmd: 'check_for_ending_goods'
                },
                scope: this,
                success: function (response) {
                    let data = Ext.decode(response.responseText),
                        msg = this._setGoodsCheckResponseStateAndReturnMessage("end_goods", data);

                    this.checkEndExpirationGoodsNotify(msg, data.is_cached, me.checkWrongSmsMessageSenderNotify);
                    VMTools.checkEndGoods();
                }
            });
        }
    },
    endingGoodsCheckMsgDialog: function (msg, data) {
        let me = this;

        if (msg.length) {
            let isEndGoodsMessage = function () {
                return msg[0] == _t(`На складе заканчивается товар, небходимо пополнить запасы`)
            };
            let isExpireDateGoodsMessage = function () {
                return msg.length == 1 && msg[0] == _t(`У некоторых товаров заканчивается или закончился срок годности`)
            };

            let setActiveTabPanelFromMessage = function (gpanel) {
                gpanel.activeGroup.items.each(function (row) {
                    if (row.title == VMTools.getTitleWithRedExclamationMark(_t('Минимальный остаток'))) {
                        if (isEndGoodsMessage()) {
                            gpanel.activeGroup.setActiveTab(row);
                        }
                    } else if (row.title == VMTools.getTitleWithRedExclamationMark(_t('Истекающий срок годности'))) {
                        if (isExpireDateGoodsMessage()) {
                            gpanel.activeGroup.setActiveTab(row);
                        }
                    }
                }, this);
            };

            let buttons = {
                ok: _t('Ок')
            };

            if (isExpireDateGoodsMessage() || isEndGoodsMessage()) {
                buttons = {
                    ok: _t('Ок'),
                    yes: _t('Посмотреть товары')
                };
            }

            Ext.Msg.show({
                title: _t('Внимание'),
                msg: msg.join('<br/><br/>'),
                buttons: buttons,
                icon: Ext.MessageBox.WARNING,
                fn: function (b) {
                    if ('ok' == b) {
                        me.setSenderErrorsCookies(data);
                    }

                    if ('yes' == b) {
                        let desc = VetManager.getModule('desktopsettings'),
                            btn = desc.getButtonByModule('stores');

                        if (btn) {
                            if (Ext.app.PanelsManager.getCurModulePath().id == 'stores-win') {
                                let stores = VetManager.getModule('stores'),
                                    gpanel = Ext.getCmp(stores.getPrefixId('stores-grouptab-panel'));

                                setActiveTabPanelFromMessage(gpanel);
                            } else {
                                desc.on('module_added', function (module_name) {
                                    if ('stores' == module_name) {
                                        let stores = VetManager.getModule('stores');
                                        stores.on('rendered', function (module) {
                                            let gpanel = Ext.getCmp(module.getPrefixId('stores-grouptab-panel'));
                                            setActiveTabPanelFromMessage(gpanel)
                                        }, this);
                                    }
                                }, this);
                                desc.menuBtnClick(btn);
                            }
                        } else {
                            Ext.MsgManager.alertError(_t('Ошибка'), _t('Не найден модуль склад в текущей сфере'));
                        }
                    }
                }
            });
        }
    },
    _setGoodsCheckResponseStateAndReturnMessage: function (key, responseData) {
        let msg = '';
        if (!responseData.is_error && responseData.is_notify) {
            switch (key) {
                case "end_goods":
                    msg = _t(`На складе заканчивается товар, небходимо пополнить запасы`);
                    break;
                case "exp_shelf":
                    msg = _t(`У некоторых товаров заканчивается или закончился срок годности`);
                    break;
                case "senders_with_error":
                    msg = _t(`Ошибки в рассылках:`);
                    break;
            }
            Ext.state.Manager.set(key, true);
        } else {
            Ext.state.Manager.set(key, false);
        }

        return msg;
    },
    setSenderErrorsCookies: function (data) {
        let ids = [];

        if (data.data.length > 0) {
            for (let i = 0; i < data.data.length; i++) {
                ids.push(data.data[i].id);
            }

            ids = ids.join(',');

            document.cookie = "sender_errors=" + ids + "; max-age=86400";
        }
    },
    getCookieByName: function (name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);

        if (parts.length === 2) return parts.pop().split(';').shift();
    },
    updateMessageSenderDataFromCookies: function (data) {
        let messageSenderErrorsCookies = this.getCookieByName('sender_errors'),
            messageSenderErrorsIds = messageSenderErrorsCookies ? messageSenderErrorsCookies.split(',') : null;

        if (messageSenderErrorsIds) {
            for(let i = data.data.length - 1; i >= 0; i--) {
                if (messageSenderErrorsIds.find(() => data.data[i].id)) {
                    data.data.splice(i, 1);
                }
            }
        }
    }
};
Ext.onReady(Ext.connectSessionChecker.start, Ext.connectSessionChecker, false);
