Ext.WorkspacesWin = Ext4.create('Ext.window.Window', {
    width: 470,
    height: 250,
    frame: true,
    layout: 'border',
    header: false,
    shadow: false,
    resizable: false,
    closable: false,
    closeAction: 'hide',
    modal: true,
    cls: ['vet-window', 'border-radius-10'],
    bodyCls: ['vet-window-body', 'noborder', 'border-radius-8'],

    store: null,
    autoRedirect: true,
    selectEvent: 'click',
    selectedWorkspace: 0,
    wsButtons: null,
    helpButton: null,
    isMobileAuth: false,
    askSipOnLogin: false,
    clinicId: 0,
    initComponent: function(){
        this.store = this.getStore();
        Ext4.Window.prototype.initComponent.apply(this, arguments);
        this.wsButtons = [
            this.child('panel button[id=workspace-btn-1]'),
            this.child('panel button[id=workspace-btn-2]'),
            this.child('panel button[id=workspace-btn-3]'),
            this.child('panel button[id=workspace-btn-4]')
        ];
        this.helpButton = this.child('panel button[id=workspaces-help-btn]');
        this.helpButton.setHandler(this.onHelpButtonClick, this);
        this.addEvents('setworkspace');
        this.addEvents('cancel');
        this.on('hide', function(){
            if (this.selectedWorkspace == 0){
                this.fireEvent('cancel', this);
            }
        }, this);
    },
    onHelpButtonClick: function(button){
        window.onbeforeunload = function(){
            return _t(`Во время выполнения следующей операции, данные, которые не сохранены на текущий момент, могут быть утеряны.`);
        };
        switch(button.action){
            case 'close':
                this.close();
                break;
        }
    },
    show: function(cfg) {
        if (cfg) {
            if (Ext.isBoolean(cfg.autoRedirect)) {
                this.autoRedirect = cfg.autoRedirect;
            } else {
                this.autoRedirect = true;
            }
            this.askSipOnLogin = Ext.isBoolean(cfg.askSipOnLogin) ? cfg.askSipOnLogin : false;
            this.clinicId = Ext.isNumber(cfg.clinicId) ? cfg.clinicId : 0;
            this.modal = cfg.modal;
            this.setClosable(cfg.closable);
            if (typeof cfg.isMobileAuth != 'undefined') {
                this.isMobileAuth = cfg.isMobileAuth;
                if (true == this.isMobileAuth) {
                    Ext.AuthQRCodeCheck.finish();
                }
            }
        }
        this.store.reload();
        Ext4.window.Window.prototype.show.apply(this);
        
        Ext4.defer(function(){
            this.wsButtons[0].focus();
        }, 250, this);
    },
    setClosable: function(closable){
        if (closable){
            this.helpButton.setText(_t('Закрыть'));
            this.helpButton.setIcon('');
            this.helpButton.href = '';
            this.helpButton.action = 'close';
        }else{
            this.helpButton.setText(_t('Начальная настройка'));
            this.helpButton.setIcon('ui/resources/images_new/pdf_ico.svg');
            this.helpButton.href = 'help/begin/begin-vetmanager.pdf';
            this.helpButton.action = 'none';
        }
    },
    getStore: function(){
        var store = new Ext.data.JsonStore({
            idProperty : 'id',
            root : 'data',
            fields : ['id', 'title', 'image'],
            url : 'ajax_login.php',
            baseParams : {
               cmd : 'get_workspaces'
            },
            listeners: {
                scope: this,
                load: function(a,b) { // fill workspaces array
                    this.refreshButtons(b);
                    if (typeof VetManager == 'undefined') return;
                    
                    var el = VetManager.getModule('desktopsettings');
                    el.workspacesArr = [];
                    for (var i in b) {
                        if ('remove' !== i) {
                            el.workspacesArr.push({ id: b[i].get('id'), title: b[i].get('title') });
                        }                        
                    } 
                    // try to set top users info
                    el.setWorkspaceName(el.workspacesArr, el.workspacesIndex);                    
                }
            },
            autoLoad : true
        });

        return store;
    },
    refreshButtons: function(recs){
        for (var i = 0; i < 4; i++){
            if (recs[i]){
                this.wsButtons[i].setText(recs[i].get('title'));
                this.wsButtons[i].setIcon('ui/desktop/images/mainicons/' + recs[i].get('image'));
                this.wsButtons[i].enable();
            }else{
                this.wsButtons[i].setText('');
                this.wsButtons[i].setIcon('ui/desktop/images/mainicons/empty.png');
                this.wsButtons[i].disable();
            }
        }
    },
    onClick: function(id){
        if (this.selectEvent == 'click'){
            this.setWorkspace(view.getRecord(node));
        }
        return;
    },
    onDblClick: function(id){
        if (this.selectEvent == 'dblclick'){
            this.setWorkspace(view.getRecord(node));
        }
        return;
    },
    setWorkspace: function(rec){
        var me = this;
        me.ajaxLoginSetWorkspace(rec);
    },
    ajaxLoginSetWorkspace: function (rec) {
        var myMask = new Ext.LoadMask(Ext.getBody(), {msg: _t('Переходим в сферу') + " '" + rec.get('title')+ "'"});
        myMask.show();
        Ext.Ajax.request({
            url: 'ajax_login.php',
            success: function(){
                this.selectedWorkspace = rec.id;
                if (false == this.isMobileAuth) {
                    this.fireEvent('setworkspace', this, rec.id, rec);
                }
                if (this.autoRedirect){
                    this.redirect();
                }
                this.hide();
            },
            scope: this,
            params: {
                cmd: 'set_workspace',
                workspace: rec.id
            }
        });
    },
    redirect: function(){
        window.location.href = 'index.php';
    },
    items: [
        {
            height: 65,
            region: 'north',
            frame: true,
            border: false,
            padding: '0 0 17 0',
            cls: ['noborder'],
            bodyCls: ['noborder', 'vet-logo-text']
        },
        {
            xtype: 'panel',
            region: 'center',
            border: true,
            frame: true,
            layout: 'border',
            cls: ['gradient', 'border-radius-8', 'border-3d'],
            bodyCls: ['vet-panel-body', 'nobackground'],
            padding: 12,
            style: {
                borderColor: '#f1fbff'
            },
            items:[
                {
                    layout: 'hbox',
                    region: 'center',
                    cls: ['noborder', 'nobackground'],
                    frame: true,
                    border: false,
                    bodyCls: ['vet-panel-body', 'nobackground'],
                    padding: 0,
                    defaults: {
                        xtype: 'button',
                        iconAlign: 'top',
                        flex: 1,
                        cls: ['vet-form-button-sphere'],
                        iconCls: ['vet-form-button-sphere-icon'],
                        border: false,
                        overCls: ['vet-form-button-sphere-over'],
                        focusCls: ['vet-form-button-sphere-focus'],
                        icon: '',
                        text: 'workspace-btn',
                        handler: function(btn){
                            var btnNo = btn.id.split('-')[2] - 1;
                            var rec = Ext.WorkspacesWin.store.getAt(btnNo);
                            if (rec){
                                Ext.WorkspacesWin.setWorkspace(rec);
                            }
                        },
                        listeners: {
                            render: function(btn){
                                btn.el.dom.onkeydown = function(e){
                                    var btn = Ext.WorkspacesWin.focusedButton;
                                    if (e.keyCode == 9){
                                        var btnNo = btn.id.split('-')[2] - 1;
                                        
                                        if (btnNo == 3){
                                            btn = Ext.WorkspacesWin.wsButtons[0];
                                            Ext.defer(btn.focus, 50, btn);
                                        }else{
                                            var nextBtn = Ext.WorkspacesWin.wsButtons[btnNo + 1];
                                            if (nextBtn.isDisabled()){
                                                btn = Ext.WorkspacesWin.wsButtons[0];
                                                Ext.defer(btn.focus, 50, btn);
                                            }
                                        }
                                    }
                                };
                            },
                            focus: function(btn){
                                Ext.WorkspacesWin.focusedButton = btn;
                            }
                        }
                    },
                    items: [
                        {
                            id: 'workspace-btn-1',
                            tabIndex: 1
                        },
                        {
                            id: 'workspace-btn-2',
                            tabIndex: 2
                        },
                        {
                            id: 'workspace-btn-3',
                            tabIndex: 3
                        },
                        {
                            id: 'workspace-btn-4',
                            tabIndex: 4
                        }
                    ]
                },
                {
                    layout: 'fit',
                    region: 'south',
                    height: 34,
                    cls: ['noborder', 'nobackground'],
                    frame: true,
                    border: true,
                    bodyCls: ['vet-panel-body', 'nobackground'],
                    padding: 0,
                    defaults: {
                        xtype: 'button',
                        iconAlign: 'left'
                    },
                    items:[
                        {
                            xtype: 'button',
                            text: _t('Начальная настройка'),
                            href: 'help/begin/begin-vetmanager.pdf',
                            cls: ['vet-form-button-link'],
                            overCls: ['vet-form-button-link-over'],
                            icon: 'ui/resources/images_new/pdf_ico.svg',
                            margin: '6 113 6 127',
                            id: 'workspaces-help-btn'
                        }
                    ]
                }
           ]
        }
    ]
});
