Ext.ns('CountdownComponent');
Ext.CountdownComponent = Ext.extend(Ext.util.Observable, {
    isStarted: false
    , intervalId: null
    , currentTabId: null
    , startDate: null
    , timeDisplay: ''
    , scope: null
    , tabId: null
    , ready: null
    , seconds: 0
    , CRITICAL_SECONDS: 30
    , CRITICAL_MESSAGE: ''
    , RESTART_EVENTS: []
    , timeDisplayTpl: "%minutes%:%seconds%"
    , countdownEl: null
    , countdownTextEl: null
    , referalButtonEl: null
    , listeners: {}
    , params: {
        finish: Ext.emptyFn
        , cancel: Ext.emptyFn
        , ready: Ext.emptyFn
        , scope: this
    }
    , constructor: function(importOptions) {
        if (importOptions && importOptions.params) {
            if (importOptions.params.finish) {
                this.params.finish = importOptions.params.finish;
            }
            if (importOptions.params.cancel) {
                this.params.cancel = importOptions.params.cancel;
            }
            if (importOptions.params.ready) {
                this.params.ready = importOptions.params.ready;
            }
            if (importOptions.params.scope) {
                this.params.scope = importOptions.params.scope;
            }
        }
        if (importOptions && importOptions.listeners) {
            var listeners = importOptions.listeners;
            delete importOptions.listeners;
        }
        Ext.CountdownComponent.superclass.constructor.apply(this, importOptions);
        if (listeners) {
            for (k in listeners) {
                if (k != 'scope') {
                    this.addListener(k, listeners[k], listeners.scope);
                }
            }
        }
        if (this.params.ready) {
            this.params.ready.call(this.params.scope);
        }
    }
    , millisToMinutesAndSeconds: function(millis) {
        var minutes = Math.floor(millis / 60000), seconds = ((millis % 60000) / 1000).toFixed(0);
        if (seconds == 60) {
            minutes++;
            seconds = 0;
        }
        var minutesStr = (minutes < 10? '0' : '') + minutes;
        var secondsStr = (seconds < 10? '0' : '') + seconds;

        var searchArray = ["%minutes%", "%seconds%"];
        var replaceArray = [minutesStr, secondsStr];

        var result = this.timeDisplayTpl;
        for (var i = searchArray.length - 1; i >= 0; i--) {
            result = result.replace(RegExp(searchArray[i], "gi"), replaceArray[i]);
        }
        this.timeDisplay = result;
    }
    , toggle: function() {
        if (this.countdownEl) {
            $(this.countdownEl).toggle('bounce', 'swing', 600);
        }
    }
    , getMsg: function() {
        var msg = this.CRITICAL_MESSAGE;
        return msg;
    }
    , start: function() {
        if (this.isStarted) {
            return false;
        } else {
            this.isStarted = true;
            this.startDate = new Date();
            this.startRestartListener();
            $(this.referalButtonEl).hide();
            var isShowedCriticalMessage = false;

            $(this.countdownEl).removeClass('critical');

            this.toggle();

            this.intervalId = setInterval(function() {
                var miliseconds = this.seconds * 1000 - ((new Date()) - this.startDate)
                    , leftSeconds = Math.round(miliseconds / 1000);

                this.millisToMinutesAndSeconds(miliseconds);
                $(this.countdownTextEl).text(this.timeDisplay);

                if (leftSeconds < this.CRITICAL_SECONDS) {
                    $(this.countdownEl).toggleClass('critical');
                    if (!isShowedCriticalMessage) {
                        isShowedCriticalMessage = true;
                        Ext.MsgManager.alertError(_t('Очень важно!'), this.getMsg());
                    }
                } else {
                    $(this.countdownEl).removeClass('critical');
                }

                if (leftSeconds <= 0) {
                    this.finish();
                }
            }.bind(this), 1000);
            this.currentTabId = this.tabId;
        }
    }
    , cancel: function() {
        clearInterval(this.intervalId);
        if (!this.isStarted) return;
        $(this.countdownEl).hide();
        $(this.referalButtonEl).show();
        this.isStarted = false;
        if (this.params && this.params.cancel) {
            this.params.cancel.call(this.scope);
        }
        this.stopRestartListener();
    }
    , finish: function() {
        clearInterval(this.intervalId);
        if (!this.isStarted) return;
        $(this.countdownEl).hide();
        $(this.referalButtonEl).show();
        this.isStarted = false;
        if (this.params && this.params.finish) {
            this.params.finish.call(this.scope);
        }
        this.stopRestartListener();
    }
    , stopRestartListener: function() {
        var me = this;
        for (var i = 0; i < this.RESTART_EVENTS.length; i++) {
            Ext.getBody().un(this.RESTART_EVENTS[i], this.updateDate, this);
            VetManager.eventManager.un('tinymce_' + this.RESTART_EVENTS[i], this.updateDate);
        }

        Ext.each(Ext.query('iframe'), function(iframe) {
            try {
                var doc = iframe.contentWindow || iframe.contentDocument;
                for (var i = 0; i < me.RESTART_EVENTS.length; i++) {
                    doc.document.body.removeEventListener(me.RESTART_EVENTS[i], me.updateDateGlobal);
                }
            } catch (err) { }
        });
        this.currentTabId = null;
    }
    , startRestartListener: function() {
        var me = this;
        if (!this.isStarted) {return;}
        for(var i = 0; i < this.RESTART_EVENTS.length; i++) {
            Ext.getBody().on(this.RESTART_EVENTS[i], this.updateDate, this);
            VetManager.eventManager.on('tinymce_' + this.RESTART_EVENTS[i], this.updateDate, this);
        }
        var task = new Ext.util.DelayedTask(function() {
            Ext.each(Ext.query('iframe'), function(iframe) {
                try {
                    var doc = iframe.contentWindow || iframe.contentDocument;
                    for(var i = 0; i < me.RESTART_EVENTS.length; i++) {
                        doc.document.body.addEventListener(me.RESTART_EVENTS[i], me.updateDateGlobal);
                    }
                } catch (err) { }
            });
        });
        task.delay(1000);
    }
    , updateDate: function() {
        this.startDate = new Date();
    }
});

Ext.CountdownInvoice = Ext.extend(Ext.CountdownComponent, {
    seconds: Ext.LockDocument.TIMEOUT_OPEN_DOCUMENT
    , referalButtonEl: '#referal-main-button-div'
    , countdownEl: '#invoice-timer-button-div'
    , countdownTextEl: '#invoice-timer-button-time'
    , RESTART_EVENTS:[
        'keypress'
        , 'click'
        , 'mousemove'
        , 'scroll'
    ]
    , CRITICAL_MESSAGE: [
        _t('Вы не заполняете документ в течение %minute минут!')
        , _t('Закройте окно документа или продолжите редактирование.')
        , _t(`Внимание: Через %second секунд окно редактирования документа закроется автоматически.`)
        , _t('Изменения не будут сохранены!')
    ].join('<br>')
    , getMsg:function(){
        var msg = this.CRITICAL_MESSAGE.replace('%minute', Math.round(this.seconds/60));
        msg = msg.replace('%second', this.CRITICAL_SECONDS);
        return msg;
    }
    , params: {
        ready: function () {
            if (Ext.get('invoice-timer-button-div')) {
                Ext.get('invoice-timer-button-div').on('click', function() {
                    if (this.currentTabId) {
                        Ext.app.PanelsManager.showModuleByNumberOrId(null, this.currentTabId);
                    }
                }.bind(this));
            }
        }
        , scope: this
    }
});
Ext.CountdownInvoice = new Ext.CountdownInvoice();

Ext.CountdownMedcard = Ext.extend(Ext.CountdownComponent, {
    seconds: Ext.LockDocument.TIMEOUT_OPEN_DOCUMENT_LONG
    , referalButtonEl: '#referal-main-button-div'
    , countdownEl: '#medcard-timer-button-div'
    , countdownTextEl: '#medcard-timer-button-time'
    , RESTART_EVENTS:[
        'keypress'
        , 'click'
        , 'mousemove'
        , 'scroll'
    ]
    , CRITICAL_MESSAGE: [
        _t('Вы не заполняете документ в течение %minute минут!')
        , _t('Закройте окно документа или продолжите редактирование.')
        , _t(`Внимание: Через %second секунд окно редактирования документа закроется автоматически.`)
    ].join('<br>')
    , getMsg:function() {
        var msg = this.CRITICAL_MESSAGE.replace('%minute', Math.round(this.seconds/60));
        msg = msg.replace('%second', this.CRITICAL_SECONDS);
        return msg;
    }
    , params: {
        ready: function () {
            if (Ext.get('medcard-timer-button-div')) {
                Ext.get('medcard-timer-button-div').on('click', function() {
                    if (this.currentTabId) {
                        Ext.app.PanelsManager.showModuleByNumberOrId(null, this.currentTabId);
                    }
                }.bind(this));
            }
        }
        , scope: this
    }
});
Ext.CountdownMedcard = new Ext.CountdownMedcard();

Ext.CountdownInventar = Ext.extend(Ext.CountdownComponent, {
    seconds: Ext.LockDocument.TIMEOUT_OPEN_DOCUMENT
    , referalButtonEl: '#referal-main-button-div'
    , countdownEl: '#inventar-timer-button-div'
    , countdownTextEl: '#inventar-timer-button-time'
    , RESTART_EVENTS:[
        'keypress'
        , 'keyup'
        , 'click'
        , 'mousemove'
        , 'scroll'
    ]
    , CRITICAL_MESSAGE: [
        _t('Вы не заполняете документ в течение %minute минут!')
        , _t('Закройте окно документа или продолжите редактирование.')
        , _t(`Внимание: Через %second секунд окно редактирования документа закроется автоматически.`)
    ].join('<br>')
    , getMsg:function() {
        var msg = this.CRITICAL_MESSAGE.replace('%minute', Math.round(this.seconds/60));
        msg = msg.replace('%second', this.CRITICAL_SECONDS);
        return msg;
    }
});

Ext.CountdownInventar = new Ext.CountdownInventar();

Ext.CountdownPayment = Ext.extend(Ext.CountdownComponent, {
    seconds: Ext.LockDocument.TIMEOUT_OPEN_DOCUMENT
    , referalButtonEl: '#referal-main-button-div'
    , countdownEl: '#payment-timer-button-div'
    , countdownTextEl: '#payment-timer-button-time'
    , RESTART_EVENTS:[
        'keypress'
        , 'click'
        , 'mousemove'
        , 'scroll'
    ]
    , CRITICAL_MESSAGE: [
        _t('Вы не выполняете оплату в течение %minute минут!')
        , _t('Закройте форму или продолжайте оплату.')
        , _t(`Внимание: Через %second секунд окно оплаты закроется автоматически.`)
    ].join('<br>')
    , getMsg: function() {
        var msg = this.CRITICAL_MESSAGE.replace('%minute', Math.round(this.seconds/60));
        msg = msg.replace('%second', this.CRITICAL_SECONDS);
        return msg;
    }
});
Ext.CountdownPayment = new Ext.CountdownPayment();

Ext.CountdownVersionVetmanager = Ext.extend(Ext.CountdownComponent, {
    seconds: 0
    , secondsTimeoutMin: 180 // задержка перед началом отсчета
    , secondsTimeoutMax: 3600
    , secondsCountdownMin: 600 // кол-во секунд для отсчета
    , secondsCountdownMax: 600
    , referalButtonEl: '#referal-main-button-div'
    , countdownEl: '#version-vetmanager-timer-button-div'
    , countdownTextEl: '#version-vetmanager-timer-button-time'
    , RESTART_EVENTS:[]
    , CRITICAL_MESSAGE: [
        _t('Внимание! Страницу необходимо обновить, страница перезагрузится когда закончится время. ' +
            'Сохраните все изменения и обновите страницу.')
    ].join('<br>')
    , CRITICAL_SECONDS: 90
    , params: {
        finish: function() {
            window.onbeforeunload = null;
            location.reload(true);
        }
        , ready: function() {
            Ext.onReady(function() {
                $(this.countdownEl).mouseenter(function() {
                    Ext.MsgManager.alertError(_t('Очень важно!'), this.getMsg());
                }.bind(this));
            }.bind(this));
        }
        , scope: this
    }
    , isInitializedCountdownVersion: false
    , start: function() {
        if (this.isStarted) {
            return false;
        } else {
            this.isStarted = true;
            var randomSeconds = Math.floor(Math.random() * (this.secondsCountdownMax - this.secondsCountdownMin + 1) + this.secondsCountdownMin);
            var randomMinutes = Math.floor(randomSeconds / 60);
            this.seconds = randomMinutes * 60;
            this.startDate = new Date();
            var isShowedCriticalMessage = false;
            $(this.countdownEl).show();
            $(this.countdownEl).removeClass('critical-alert');

            this.intervalId = setInterval(function () {
                var miliseconds = this.seconds * 1000 - ((new Date()) - this.startDate)
                    , leftSeconds = Math.round(miliseconds / 1000);

                this.millisToMinutesAndSeconds(miliseconds);
                $(this.countdownTextEl).text(this.timeDisplay);

                if (leftSeconds < this.CRITICAL_SECONDS) {
                    $(this.countdownEl).toggleClass('critical-alert');
                    if (!isShowedCriticalMessage) {
                        isShowedCriticalMessage = true;
                        Ext.MsgManager.alertError(_t('Очень важно!'), this.getMsg());
                    }
                } else {
                    $(this.countdownEl).removeClass('critical-alert');
                }

                if (leftSeconds <= 0) {
                    this.finish();
                }
            }.bind(this), 1000);
        }
    }
    , initCountdownVersion: function() {
        if (this.isInitializedCountdownVersion) {
            return false;
        }
        this.isInitializedCountdownVersion = true;
        var randomTimeoutSeconds = Math.floor(Math.random() * (this.secondsTimeoutMax - this.secondsTimeoutMin + 1) + this.secondsTimeoutMin);
        var randomTimeoutMinutes = Math.floor(randomTimeoutSeconds / 60);
        var randomTimeoutMilliseconds = randomTimeoutMinutes * 60 * 1000;
        setTimeout(function() {
            this.start();
        }.bind(this), randomTimeoutMilliseconds);

    }
});

Ext.CountdownVersionVetmanager = new Ext.CountdownVersionVetmanager();