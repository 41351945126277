Ext.ns('Tariff');
Tariff.CurrentPlanInfo = Ext.extend(Ext.DataView, {
    border: false    
    , initComponent: function(config){
        this.store = new Tariff.CurrentPlanStore({});
        
        this.addEvents(
            'receive_current_tariff'
            , 'error_current_tariff'
            , 'click_update'
            , 'update_start'
            , 'update_finish'
        );
        this.on('click_update', this.actionUpdate, this);  
        this.on('update_start', this.onUpdateStart, this);
        this.on('update_finish', this.onUpdateFinish, this);
        Tariff.CurrentPlanInfo.superclass.initComponent.call(this, config);  

   }    
    , tpl: new Ext.XTemplate(
        '<tpl for=".">'    
            , '<div class="current-plan">'
                , '<div class="current-plan__description">' + _t('Информация о вашем текущем тарифе') + ':</div>'
                ,  '<div class="current-plan__info"><span class="current-plan__label">' + _t('Оплачено до') + ': </span><span class="current-plan__value">{[this.dateToFormat(values.valid_untill)]}</span></div>'
                ,  '<div class="current-plan__info"><span class="current-plan__label">' + _t('Количество пользователей') + ': </span><span class="current-plan__value">{[this.getUsersAllowed(values)]}</span></div>'
                ,  '<div class="current-plan__info"><span class="current-plan__label">' + _t('Локальная версия') + ': </span><span class="current-plan__value">{[this.booleanToFormat(values.is_local_version)]}</span></div>'
            , '</div>'
        , '</tpl>'
        , {
            dateToFormat: function(v){
                var result = Common.renderDateTime(v);
                if ((new Date(v)).clearTime() < (new Date()).clearTime()) {
                    result = '<span style="color: #c2000e;">' + result + '</span>';
                }
                return result;
            }
            , booleanToFormat: function(v){
                return Common.rendererYesNo(v);
            }
            , getUsersAllowed: function(o) {
                if (o.users_registered > o.users_allowed) {
                    return '<span style="color: #c2000e;">' + o.users_allowed + '</span>';
                } else {
                    return o.users_allowed;
                }
            }
        }        
    )
    , anchor: '100%'
    , actionUpdate: function(button, event){        
        Ext.connectSessionChecker.checkTariff();
        var task = new Ext.util.DelayedTask(function(){
            this.fireEvent('update_finish');            
        }, this);
        this.fireEvent('update_start');
        task.delay(5000);
    }
    , onUpdateStart: function(){
        this.loadMask.show();
    }
    , onUpdateFinish: function(){
        this.getStore().load();
    }
    , listeners: {
        afterrender: function(panel) {  
            panel.loadMask = new Ext.LoadMask(
                panel.el.dom
                , { msg: _t('Отправляем запрос на сервер') }
            );
            panel.getStore().load();
        }
    }    
});