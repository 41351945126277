Ext.namespace("Ext.ux.window");
var windowSipNumberWidth = 470;
Ext.ux.window.SipNumber = Ext.extend(Ext.Window, {
    title: _t('Установка СИП номера'),
    buttonAlign: 'center',
    modal: true,
    width: windowSipNumberWidth,
    name: 'window_sip_number',
    autoHeight: true,
    resizable: false,
    cls: ['vet-window', 'border-radius-10'],
    bodyCls: ['vet-window-body', 'noborder', 'border-radius-8'],
    sipNumber: 0,
    clinicId: 0,
    onlyAvailable: 0,
    closable: false,
    sipNumberCombo: null,
    layout: 'form',
    listeners: {
        close: function(w){
            if (!_CURRENT_USER_SIP_NUMBER || parseInt(_CURRENT_USER_SIP_NUMBER) <= 0) {
                w.show();
            }
        },
        scope: this
    },
    doClose: function() {
        var me = this;
        if (parseInt(_CURRENT_USER_SIP_NUMBER) > 0) {
            me.fireEvent("close", me);
            me.destroy();
        } else {
            me.show();
        }
    },
    initComponent: function(config) {
        var me = this;
        me.buttons = me.buildButtons();
        me.items = me.buildItems();
        me.tbar = me.buildTbar();
        Ext.ux.window.SipNumber.superclass.initComponent.apply(me, arguments);
    },
    buildButtons: function () {
        var me = this;
        return [
            {
                xtype: 'vetbutton',
                text:  _t('Выбрать'),
                name: 'select_sip',
                handler: function(button) {
                    var sipNumberField = button.findParentByType('window').find('name', 'sip_number')[0];

                    if (sipNumberField && sipNumberField.getValue() > 0) {
                        me.sipNumber = sipNumberField.getValue();
                    } else {
                        var errorTxt = button.findParentByType('window').find('name', 'error_text')[0];
                        errorTxt.show();
                        return;
                    }
                    me.saveNumber();
                }
            }
        ];
        
    },
    buildItems: function () {
        var me = this;
        var items = [
            {
                xtype: 'label',
                hidden: true,
                text: _t('Выбирите СИП номер'),
                name: 'error_text',
                style: {
                    padding: '100px',
                    margin: '20px',
                    color: 'red'
                }
            },
            {
                xtype:'label',
                hidden: true,
                text: _t("Все номера заняты"),
                name: 'no_sip_number',
                style: {
                    padding: '100px',
                    margin: '20px',
                    color: 'red'
                }
            },
            {
                xtype:'label',
                hidden: true,
                text: _t("СИП номер не установлен"),
                name: 'error_set_sip_number',
                style: {
                    padding: '100px',
                    margin: '20px',
                    color: 'red'
                }
            },
            {
                xtype:'form',
                labelWidth: 130,
                frame: false,
                border: false,
                autoWidth: true,
                bodyStyle: {
                    padding: '15px',
                    margin: '0px',
                    backgroundColor: '#DFE8F6'
                },
                items: [
                    {
                        xtype:'label',
                        text: _t(`Выберите внутренний номер телефона вашего рабочего места *:`),
                    },
                    me.buildSipNumberCombo()
                ]
            },
            me.buildGridSipInfo()

        ];
        return items;
    },

    buildTbar: function () {
        var me = this;
        return [{
            xtype: 'vetbutton',
            width: 300,
            text: _t('Посмотреть занятые номера'),
            name: 'button_show_sip',
            scope: me,
            handler: function() {
                var gridSipInfo = me.find( 'name', 'grid_sip_info' )[0];
                if (gridSipInfo.hidden) {
                    gridSipInfo.getStore().load();
                    gridSipInfo.show();
                    me.getTopToolbar().find('name', 'button_show_sip')[0].setText(_t('Скрыть таблицу с номерами'));
                } else  {
                    gridSipInfo.hide();
                    me.getTopToolbar().find('name', 'button_show_sip')[0].setText(_t('Посмотреть занятые номера'));
                }
            }
        }];
    },

    buildSipNumberCombo: function() {
        var me = this;
        me.sipNumberCombo = new Ext.form.ComboBox({
                valueField: 'sip',
                displayField: 'sip',
                fieldLabel: _t('Выбирите СИП номер'),
                hideLabel: true,
                allowBlank: false,
                forceSelection: true,
                editable: true,
                mode: 'local',
                name: 'sip_number',
                style: "height:32px;",
                anchor: '100%',
                store: new Ext.data.JsonStore({
                    idProperty: 'id',
                    root: 'data',
                    fields: ['id', 'sip'],
                    url: 'ajax_voip.php',
                    autoLoad: true,
                    baseParams: {
                        cmd: 'get_sip_numbers',
                        clinic_id: me.clinicId,
                        only_available: me.onlyAvailable
                    },
                    listeners:{
                        scope: me,
                        load: function (store, records) {
                            if (records.length == 0) {
                                var noSipNumber = me.find('name', 'no_sip_number')[0];
                                noSipNumber.show();
                                me.buttons[0].disable();
                            }
                        }
                    }
                }),
                listeners:{
                    expand: function () {
                        var me = this;
                        var win = me.findParentByType('window');
                        win.find('name', 'error_text')[0].hide();
                        win.find('name', 'error_set_sip_number')[0].hide();
                    },
                    collapse: me.restoreWindowWidth.bind(me),
                    blur: me.restoreWindowWidth.bind(me),
                    invalid: me.restoreWindowWidth.bind(me),
                    keypress: me.restoreWindowWidth.bind(me)
                }
            });
        return me.sipNumberCombo;
    },
    saveNumber: function () {
        var me = this;
        Ext.Ajax.request({
            url: 'ajax_voip.php',
            scope: this,
            params: {
                cmd: 'set_sip_number',
                sip_number: me.sipNumber
            },
            success: function(response) {
                Common.showErrors(response);
                var data = Ext.decode(response.responseText).data;
                if (data && data.sip_number && parseInt(data.sip_number) > 0) {
                    _CURRENT_USER_SIP_NUMBER = data.sip_number;
                    me.close();
                } else {
                    me.sipNumberCombo.setValue('');
                    me.sipNumberCombo.getStore().reload();
                    var errorSetSipNumber = me.find('name', 'error_set_sip_number')[0];
                    errorSetSipNumber.show();
                }
            }
        });
    },
    buildGridSipInfo: function () {
        var store = new Ext.data.JsonStore({
            idProperty: 'sip_number',
            root: 'data',
            fields: ['sip_number', 'user'],
            url: 'ajax_voip.php',
            autoLoad: false,
            baseParams: {
                cmd: 'get_info_sip_numbers_busy'
            },
            listeners: {
                'exception': function (dataproxy, type, action, options, response, arg) {
                    if (response.status == 200 && response.responseText) {
                        Common.showErrors(response);
                    }
                }
            }
        });

        var grid = new Ext.grid.GridPanel({
            store: store,
            height: 100,
            width: 440,
            hidden: true,
            name: 'grid_sip_info',
            border: false,
            autoLoad: false,
            colModel: new Ext.grid.ColumnModel({
                columns: [{
                    header: _t('Сип номер'),
                    width: .25,
                    dataIndex: 'sip_number'

                },{
                    header: _t('Пользователь'),
                    width: .75,
                    dataIndex: 'user'
                }]
            }),
            viewConfig: {
                forceFit: true,
            }
        });
        return grid;
    },
    restoreWindowWidth: function() {
        var me = this;
        me.setWidth(windowSipNumberWidth);
    }
});
Ext.reg('windowsipnumber', Ext.ux.window.SipNumber);