Ext.UserSessionChange = {
    lockWindow: null
    , allBorders: false
    , lastUsersDataView: null
    , _userButtonIds: null
    , isZashel: false
    , init: function() {
    }
    , showLockWindow: function(isLocked) {
        isLocked = isLocked || false;
        this._userButtonIds = [];
        if (this.isZashel) {
            return;
        }
        this.isZashel = true;

        this.lockWindow = Ext4.create('Ext.window.Window', {
            width: 620
            , height: 410
            , frame: true
            , layout: {
                type: 'vbox'
                , pack: 'start'
                , align: 'stretch'
            }
            , header: false
            , shadow: false
            , resizable: false
            , closable: false
            , closeAction: 'destroy'
            , modal: true
            , cls: ['vet-window', 'border-radius-10']
            , bodyCls: ['vet-window-body', 'noborder', 'border-radius-8']
            , listeners: {
                scope: this
                , beforeclose: function () {
                    this.isZashel = false;
                    var masks = Ext4.query('.x4-mask');

                    Ext4.each(masks, function (m) {
                        if ('0.9' == m.style.opacity && 'url("ui/desktop/images/background.jpg")' == m.style.background) {
                            m.style.background = '';
                            m.style.opacity = '';
                        }
                    }, this);

                    stopTimeAgo();
                }
                , show: function () {
                    var masks = Ext4.query('.x4-mask')
                        , maxZindex = 0
                        , maxMaskNum = 0
                        , index = 0
                        , body = Ext.getBody()
                        , bodySize = {
                            height: body.getHeight()
                            , width: body.getWidth()
                        };

                    Ext4.each(masks, function (m) {
                        if (m.style.zIndex != ''
                                && Ext.get(m).getHeight() >= bodySize.height
                                && Ext.get(m).getWidth() >= bodySize.width) {
                            if (maxZindex < m.style.zIndex*1) {
                                maxZindex = m.style.zIndex*1;
                                maxMaskNum = index;
                            }
                        }
                        index++;
                    }, this);

                    if (masks.length > 0) {
                        var mask = masks[maxMaskNum];
                        mask.style.background = 'url(ui/desktop/images/background.jpg)';
                        mask.style.opacity = 0.9;
                    }
                }
                , afterrender: function () {
                    var self = this;
                    this._getFields().password.focus(false, 200);

                    new Ext.util.DelayedTask(function() {
                        self.lastUsersDataView.store.loadData(UserVisit.getInstance().getArrayList());
                    }, this).delay(300);
                }
            }
            , items: this.getLockItems(isLocked)
        });

        this._lockSession({
            callback: function() {
                Ext.MsgManager.alert(_t('Внимание'), _t('Сессия приостановлена'));
            }
        });
        Common.removeLocalStorageNotAsk();
        this.lockWindow.show();
    }
    , _getFields: function () {
        return {
            password: this.lockWindow.query('textfield[name="password"]')[0]
            , login: this.lockWindow.query('displayfield[name="login"]')[0]
            , clinicId: this.lockWindow.query('hiddenfield[name="clinic_id"]')[0]
            , userId: this.lockWindow.query('hiddenfield[name="user_id"]')[0]
            , workspaceId: this.lockWindow.query('hiddenfield[name="workspace_id"]')[0]
        };
    }
    , getLastUsersTemplate: function () {
        var lastUsersTemplate = new Ext4.XTemplate(
            '<tpl for=".">'
                , '<div class="vet-form gradient border-radius-8 border-3d" style="margin-top: 5px; ">'
                    , '<table border="0" style="width: 100%;">'
                        , '<tbody>'
                            , '<tr>'
                                , '<td><div style="padding: 5px 0px 5px 10px;font-size: 18px;">{login}</div>'
                                    , '<time class="timeago" style="color: cadetblue;font-size: 12px;padding: 0px 0px 5px 10px;" title="{date}"></time>'
                                , '</td>'
                                , '<td style="width: 66px;padding: 10px;">'
                                    , '<div class="x4-reset">'
                                        , '<div class="x4-btn vet-form-button border-radius-4 x4-btn-default-small x4-noicon x4-btn-noicon x4-btn-default-small-noicon" style="border-width:1px 1px 1px 1px;">'
                                            , '<em>'
                                                , '<button id="ch-ses-user-id-button-{user_id}" type="button" class="x4-btn-center" hidefocus="true" role="button" autocomplete="off" style="height: 30px;">'
                                                    , '<span class="x4-btn-inner" style="">{[this.enterText()]}</span>'
                                                    , '<span class="x4-btn-icon"></span>'
                                                , '</button>'
                                            , '</em>'
                                        , '</div>'
                                    , '</div>'
                                , '</td>'
                            , '</tr>'
                        , '</tbody>'
                    , '</table>'
                , '</div>'
            , '</tpl>'
            , {
                enterText: function () {
                    return _t('Войти');
                }
            }
        );

        lastUsersTemplate.compile();

        var ts = new Ext4.data.JsonStore({
            fields: ['user_id', 'clinic_id', 'login', 'date', 'workspace_id']
            , proxy: {
                type: 'memory'
                , reader: {
                    type: 'json'
                    , root: 'data'
                    , idProperty: 'user_id'
                }
            }
        });

        ts.on('datachanged', function (aa) {
            var index = 0;
            new Ext.util.DelayedTask(function() {
                Ext4.each(aa.data.items, function (row) {
                    var btn = Ext4.get("ch-ses-user-id-button-" + row.get('user_id'));
                    btn.dom.rowId = row.get('user_id');

                    btn.on('mouseover', function (b) {
                        b.currentTarget.parentElement.parentElement.classList.add('vet-form-button-over');
                        // b.currentTarget.classList.add('vet-form-button-over');
                    });
                    btn.on('mouseout', function (b) {
                        b.currentTarget.parentElement.parentElement.classList.remove('vet-form-button-over');
                        // b.currentTarget.classList.remove('vet-form-button-over');
                    });
                    btn.on('mousedown', function (b) {
                        b.currentTarget.parentElement.parentElement.classList.add('x4-vet-form-button-pressed');
                    });
                    btn.on('mouseup', function (b) {
                        b.currentTarget.parentElement.parentElement.classList.remove('x4-vet-form-button-pressed');
                    });

                    btn.on('click', function (b) {
                        var index = ts.findBy(function (row) {
                            if (row.data.user_id == b.currentTarget.rowId) { return true; }
                            return false;
                        });

                        var res = ts.getAt(index),
                            fields = this._getFields();

                        fields.login.setValue(res.get('login'));
                        fields.userId.setValue(res.get('user_id')*1);
                        fields.clinicId.setValue(_CLINIC_ID);
                        fields.workspaceId.setValue(res.get('workspace_id')*1);
                        fields.password.focus(true, 200);
                    }, this);

                    if (0 == index) {
                        var fields = this._getFields();

                        fields.login.setValue(row.get('login'));
                        fields.userId.setValue(row.get('user_id')*1);
                        fields.clinicId.setValue(_CLINIC_ID);
                        fields.workspaceId.setValue(row.get('workspace_id')*1);
                        fields.password.focus(true, 200);
                    }

                    timeAgo();
                    index++;
                }, this);
            }, this).delay(300);
        }, this);

        this.lastUsersDataView = null;
        this.lastUsersDataView = new Ext4.DataView({
           tpl: lastUsersTemplate
            , enableDD: false
            , multiSelect: false
            , padding: '0px 5px'
            , border: this.allBorders
            , store: ts
            , singleSelect: true
            , emptyText: _t('Нет данных')
            , style:{
                "overflow-y" : "auto"
                , "height": "100%"
            }
        });

        return this.lastUsersDataView;
    }
    , getLockItems: function (isLocked) {
        var items = [];

        if (isLocked) {
            items.push({
                xtype: 'form'
                , height: 30
                , border: this.allBorders
                , layout: 'fit'
                , cls: ['vet-form']
                , bodyCls: ['vet-panel-body', 'nobackground']
                , padding: '0 80px'
                , html: '<div style="font-size: 24px;">'+ _t('Выполнен вход с другого компьютера') +'</div>'
            });
        } else {
            items.push({
                xtype: 'form'
                , height: 30
                , border: this.allBorders
                , layout: 'fit'
                , cls: ['vet-form']
                , bodyCls: ['vet-panel-body', 'nobackground']
                , padding: '0 100px 0 120px'
                , html: '<div style="font-size: 24px;">' + _t('Быстрая смена пользователя') + '</div>'
            });
        }

        items.push({
            xtype: 'form'
            , border: this.allBorders
            , cls: ['vet-form']
            , bodyCls: ['vet-panel-body', 'nobackground']
            , layout: {
                type: 'hbox'
                , pack: 'start'
                , align: 'stretch'
            }, items: [
                {
                    xtype: 'displayfield'
                    , padding: '0px 10px'
                    , value: _t('Последние пользователи') + ':'
                    , flex: 1
                },{
                    xtype: 'displayfield'
                    , fieldLabel: _t('логин')
                    , value: ''
                    , anchor: '100%'
                    , labelAlign: 'right'
                    , margin: '0px 0px 0px 100px'
                    , name: 'login'
                    , style: {
                        'color': 'cadetblue',
                    }
                    , fieldStyle: {
                        'font-weight': 'bold'
                        , 'font-size': '18px'
                        , 'margin-top': '-2px'
                    }
                        , flex: 1
                }
                ]
                },{
                xtype: 'form'
                , flex: 1
                , border: this.allBorders
                , cls: ['vet-form']
                , bodyCls: ['vet-panel-body', 'nobackground']
                , layout: {
                    type: 'hbox'
                    , pack: 'start'
                    , align: 'stretch'
                }
                , items: [
                    {
                        xtype: 'panel'
                        , flex: 1
                        , border: this.allBorders
                        , padding: '0px 5px 0px 5px'
                        , labelWidth: 25
                        , cls: ['vet-form']
                        , bodyCls: ['vet-panel-body', 'nobackground']
                        , items: [
                            this.getLastUsersTemplate()
                        ]
                    }, {
                        xtype: 'form'
                        , name: 'credentials_form'
                        , border:  this.allBorders
                        , width: 243
                        , cls: ['vet-form']
                        , bodyCls: ['vet-panel-body', 'nobackground']
                        , padding: '0px 5px'
                        , items: [
                            {
                                xtype: 'hiddenfield'
                                , name: 'clinic_id'
                                , value: 1
                            }, {
                                xtype: 'hiddenfield'
                                , name: 'user_id'
                                , value: 0
                            }, {
                                xtype: 'hiddenfield'
                                , name: 'workspace_id'
                                , value: 1
                            }, {
                                xtype: 'textfield'
                                , inputType: 'password'
                                , anchor: '100%'
                                , margin: '2'
                                , name: 'password'
                                , value: ''
                                , cls: ['vet-form-field', 'vet-form-field-pass']
                                , allowBlank: false
                                , emptyText: _t('Пароль')
                                , listeners: {
                                    scope: this
                                    , specialkey: function (field, e) {
                                        if (e.getKey() == e.ENTER) {
                                            var form = field.up('form').getForm();
                                            this.loginButton.call(this);
                                        }
                                    }
                                }
                        }, {
                            xtype: 'button'
                            , anchor: '100%'
                            , text: _t('Войти')
                            , cls: ['vet-form-button', 'border-radius-4']
                            , overCls: ['vet-form-button-over']
                            , pressedCls: ['vet-form-button-pressed']
                            , margin: '10 2'
                            , scope: this
                            , handler: this.loginButton
                        }, {
                            xtype: 'button'
                            , anchor: '100%'
                            , text: _t('Войти под другим<br/>пользователем')
                            , cls: ['vet-form-button-link']
                            , overCls: ['vet-form-button-link-light-over']
                            , margin: '0 5'
                            , scope: this
                            , handler: this.redirectToLogin
                        },  {
                            xtype: 'panel'
                            , frame: true
                            , width: 240
                            , height: 184
                            , margin: '12 0 0 0'
                            , border: this.allBorders
                            , cls: ['noborder']
                            , bodyCls: ['noborder', 'vet-logo-big']
                        }
                    ]
                }
            ]
        });
        return items;
    }
    , loginButton: function () {
        var fields = this._getFields();

        if (fields.password.getValue() != '') {
            if (_CURRENT_USER == fields.userId.getValue()*1) {
                this._checkLogin({
                    fields: fields
                    , scope: this
                    , callback: function (res) {
                        if ('continue_session' == res) {
                            this._unlockSession({
                                scope: this
                                , callback: function () {
                                    Ext.MsgManager.alert(_t('Внимание'), _t('Сессия продолжена'));
                                    this.lockWindow.close();
                                }
                            });
                        } else if ('kill_other_sessions' == res) {
                            this._unlockSession({
                                scope: this
                                , callback: function () {
                                    Ext.MsgManager.alert(_t('Внимание'), _t('Сессия продолжена'));
                                    this.lockWindow.close();
                                }
                            });
                        }
                    }
                });
            } else {
                this._checkLogin({
                    fields: fields
                    , scope: this
                    , callback: function (res) {
                        if ('kill_other_sessions' == res) {
                            // debugger;
                            this._unlockSession({
                                scope: this
                                , other_user_id_to_lock: fields.userId.getValue()
                                , callback: function () {
                                    this._checkLogin({
                                        fields: fields
                                        , scope: this
                                        , callback: function (res) {
                                            window.onbeforeunload = null;
                                            this.lockWindow.close();
                                            Ext.WorkspacesWin.redirect();
                                        }
                                    });
                                }
                            });
                        } else if ('continue_session' == res) {
                            window.onbeforeunload = null;
                            this.lockWindow.hide();
                            Ext.WorkspacesWin.redirect();
                        }
                    }
                });
            }
        }
    }
    , redirectToLogin: function () {
        window.onbeforeunload = null;
        Ext.logoutFn();
    }
    , _lockSession: function (params) {
        _IS_SESSION_LOCKED = true;

        Ext4.Ajax.request({
            url: 'ajax_login.php'
            , scope: this
            , params: {
                cmd: 'lock_session'
                , SUPERAJAX: 1
                , _SESSION_KEY: _SESSION_KEY
            }
            , success: function () {
                if (params.callback) {
                    params.callback.call(params.scope || this);
                }
            }
        });

        this._closeOpenDocuments();
    }
    , _unlockSession: function (params) {
        _IS_SESSION_LOCKED = false;

        Ext4.Ajax.request({
            url: 'ajax_login.php'
            , scope: this
            , params: {
                cmd: 'unlock_session'
                , SUPERAJAX: 1
                , other_user_id_to_lock: params.other_user_id_to_lock || 0
            }
            , success: function () {
                if (params.callback) {
                    params.callback.call(params.scope || this);
                }
            }
        });
    }
    , _closeOpenDocuments: function () {
        Ext.CountdownInvoice.finish();
        Ext.CountdownInvoice.finish();
    }
    , _checkLogin: function (params) {
        Ext4.Ajax.request({
            url: 'ajax_login.php'
            , scope: this
            , params: {
                cmd: 'check_login'
                , SUPERAJAX: 1
                , 'f[login]': params.fields.login.getValue()
                , 'f[password]': params.fields.password.getValue()
                , clinic: params.fields.clinicId.getValue()
                , skip_time_zone_check: false
                , workspace_id: params.fields.workspaceId.getValue()*1
                , client_datetime: new Date().format('Y-m-d H:i:s')
            }
            , success: function(res) {
                var result = Ext.decode(res.responseText);

                if (!result.is_error) {
                    params.callback.call(params.scope, 'continue_session');
                } else {
                    if (result.user_already_working && result.messages) {
                        Ext.Msg.show({
                            title: _t('Внимание')
                            , msg: _t('Пользователь с таким логином<br/>уже работает в системе!')
                            , buttons: {
                                ok: _t('Ок')
                                , yes: _t('Все равно войти')
                            }
                            , icon: Ext.MessageBox.WARNING
                            , fn: function(b) {
                                if ('yes' == b) {
                                    params.callback.call(params.scope, 'kill_other_sessions');
                                }
                            }
                        });
                    } else if (result.messages) {
                        Ext3.MsgManager.alertError(_t('Внимание')
                            , result.messages.join('<br />')
                            , function () {
                                params.fields.password.focus(true, 200);
                            }, this);
                    }
                }
            }
        });
    }
};
Ext.onReady(function() {
    Ext.UserSessionChange.init();
});

// Ext.onReady(function () {
//     new Ext.util.DelayedTask(function() {
//         Ext.UserSessionChange.showLockWindow();
//     }, this).delay(1000);
// }, Ext.UserSessionChange);