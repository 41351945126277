Ext.ns('Tariff');
Tariff.Prolongation = Ext.extend(Ext.Panel, {
    border: false
    , frame: false
    , layout: 'anchor'
    , needSetCurrentPlan: false
    , initComponent: function(config){
        this.billingUrl = Tariff.Common.billing.url;
        if (!window.navigator.onLine) {
            alert(this.getNonConnectText());
        }
        this.productsStore = new Tariff.ProductsStore();
        this.selectTariffForm = this.getSelectTariffForm();
        this.selectPlanInfoPanel = this.getSelectPlanInfo();
        this.items = [
            this.selectTariffForm
            , this.selectPlanInfoPanel
        ];
        this.addEvents(
            'select_tariff_period'
            , 'change_product_qty'
        );
        this.productsStore.on('load', this.onProductsStoreLoad, this)
        this.on('select_tariff_period', this.onSelectTariffCombo, this);
        this.on('select_product', this.onSelectProduct, this);
        this.on('change_product_qty', this.onChangeProductQty, this);

        this.reloadData();
        this.subscribeOnCurrentTariff();
        Tariff.Prolongation.superclass.initComponent.call(this, config);
    }
    , subscribeOnCurrentTariff: function(){
        if (this.currentTariff) {
            this.currentTariff.on('update_start', this.onUpdateStartCurrentPlan, this);
            this.currentTariff.on('update_finish', this.onUpdateFinishCurrentPlan, this);
        }
    }
    , onUpdateStartCurrentPlan: function(){
        if (this.loadMask) this.loadMask.show();
    }
    , onUpdateFinishCurrentPlan: function(){
        this.reloadData();
    }
    , reloadData: function(){
        this.productsStore.load();
    }
    , getSelectTariffForm: function(){
        return new Ext.Panel({
           border: false
           , frame: false
           , anchor: '100%'
           , padding: 5
           , layout: 'form'
           , items: [
               this.getPeriodCombo()
               , this.getTariffCombo()
               , {
                   xtype: 'numberfield'
                   , name: 'qty'
                   , value: 1
                   , minValue: 1
                   , maxValue: 11
                   , hidden: true
                   , fieldLabel: _t('Количество месяцев')
                   , allowBlank: false
                   , enableKeyEvents: true
                   , allowDecimals: false
                   , listeners: {
                       keyup: function(field){
                           var self = this;
                           setTimeout(function(){
                               self.fireEvent('change_product_qty', field);
                           }, 50);
                       }
                       , invalid: function(){
                           this.selectPlanInfoPanel.hide();
                       }
                       , valid: function(){
                           this.selectPlanInfoPanel.show();
                       }
                       , scope: this
                   }
               }
           ]
        });
    }
    , getPeriodCombo: function(){
        return new Ext.form.ComboBox ({
            fieldLabel: _t('Период')
            , store: new Ext.data.ArrayStore({
                fields: ['period', 'title']
                , data: [
                    ['month', _t('Месяц')]
                    , ['year', _t('Год')]
                    , ['2year', _t('2 Года')]
                ]
                , idIndex: 0
            })
            , mode: 'local'
            , displayField: 'title'
            , valueField: 'period'
            , name: 'period'
            , triggerAction: "all"
            , listeners: {
                select: function(c, record){
                    this.fireEvent('select_tariff_period', record);
                    VetManager.GlobalEvents.fireEvent('vm_period_changed', {
                        value: record.get('title')
                    });
                }
                , scope: this
            }
            , disabled: true
        });
    }
    , getTariffCombo: function(){
        return new Ext.form.ComboBox ({
            fieldLabel: _t('Тариф')
            , store: new Ext.data.ArrayStore({
                fields: ['id', 'title', 'product']
                , data: []
                , idIndex: 0
                , listeners: {
                    load: function (store, records){
                        this.fireEvent('select_product_store_load', records);
                    }
                }
            })
            , mode: 'local'
            , displayField: 'title'
            , valueField: 'id'
            , name: 'product_id'
            , triggerAction: "all"
            , width: 380
            , listeners: {
                select: function(c, record){
                    this.fireEvent('select_product', record);
                    VetManager.GlobalEvents.fireEvent('vm_tariff_changed', {
                        value: record.get('title')
                    });
                }
                , scope: this
            }
            , disabled: true
        });
    }
    , getNonConnectText: function(){
        return _t('Для оплаты, необходимо подключение к интернет. Невозможно подключиться к сети интерент, ' +
            'обновите страницу и попробуйте снова');
    }
    , getSelectPlanInfo: function() {
        var selectPlanTemplate = new Ext.XTemplate(
            '<div class="select-plan">'
                ,  '<div class="select-plan__info"><span class="select-plan__label">'+_t('К оплате')+': </span><span class="select-plan__value">{amount} '+_t('Руб.')+'</span></div>'
                ,  '<div class="select-plan__info">{[values.product.name]}</div>'
                ,  '<div class="select-plan__info"><a class="select-plan__buy" href="'+this.billingUrl+'/tariff/buy?domain_name={domain_name}&product_id={product_id}&current_host={current_host}&qty={qty}&auth_key={auth_key}" onclick="window.onbeforeunload = null;VetManager.GlobalEvents.fireEvent(LaLastTimeTime)">'+_t('Оплатить')+'</a></div>'
            , '</div>'
        );
        return new Ext.Panel({
            border: false
            , tpl: selectPlanTemplate
            , anchor: '100%'
            , height: 200
        });
    }
    , onSelectProduct: function(record){
        var qtyField = this.selectTariffForm.find('name', 'qty')[0]
        , qty = (!qtyField.getValue()) ? 1 : qtyField.getValue()
        , product = record.get('product')
        , periodCombo = this.selectTariffForm.find('name', 'period')[0];


        var selectProperty = {
            domain_name: _DOMAIN_NAME
            , auth_key: Ext.app.properties.get('clinic_billing_key')
            , product_id: record.get('id')
            , product: product
            , current_host: window.location.host
            , qty: qty
            , amount: product.final_price_with_tax*qty
        };

        if (periodCombo.getValue() !== "month") {
            qtyField.hide();
        } else {
            qtyField.show();
        }
        this.selectPlanInfoPanel.update(selectProperty);
    }
    , onSelectTariffCombo: function(record){
        var filter = {
            period: record.get('period')
            , is_change_tariff: false
        };
        if (this.currentTariff) {
            var tariff = this.currentTariff.getStore().getAt(0);
            //if it paid tariff
            if (tariff.get('users_allowed')*1 !== 9999) {
                filter.users_allowed = tariff.get('users_allowed')*1;
            }
        }
        var productData = this.productsStore.getTariffData(filter);
        var tarrifCombo = this.selectTariffForm.find('name', 'product_id')[0];
        var qtyField = this.selectTariffForm.find('name', 'qty')[0];
        tarrifCombo.getStore().loadData(productData);
        tarrifCombo.enable();
        tarrifCombo.clearValue();
        qtyField.setValue(1);
        qtyField.hide();
        if (tarrifCombo.getStore().getCount() === 1) {
            var recordTariff = tarrifCombo.getStore().getAt(0);
            tarrifCombo.setValue(recordTariff.get('id'));
            this.onSelectProduct(recordTariff);
            VetManager.GlobalEvents.fireEvent.defer(250, VetManager.GlobalEvents, ['vm_tariff_changed', {
                value: recordTariff.get('title')
            }]);
        }
    }
    , onChangeProductQty: function(qtyField){
        var qty = (!qtyField.getValue()) ? 1 : qtyField.getValue()
        , productCombo = this.selectTariffForm.find('name', 'product_id')[0]
        , recordProduct = productCombo.getStore().getById(productCombo.getValue())
        , product = recordProduct.get('product')

        var selectProperty = {
            domain_name: _DOMAIN_NAME
            , product_id: recordProduct.get('id')
            , product: product
            , current_host: window.location.host
            , qty: qty
            , amount: product.final_price_with_tax*qty
            , auth_key: Ext.app.properties.get('clinic_billing_key')
        };
        this.selectPlanInfoPanel.update(selectProperty);
    }
    , onProductsStoreLoad: function(products){
        var periodCombo = this.selectTariffForm.find('name', 'period')[0];
        periodCombo.enable();
        this.loadMask.hide();
    }
    , listeners: {
        afterrender: function(panel) {
            panel.loadMask = new Ext.LoadMask(
                panel.el.dom
                , { msg: _t('Обновление') }
            );
            setTimeout(function(){
                panel.loadMask.show();
            }, 50);
        }
    }
});