Ext.ns('Tariff');
Tariff.Common = {
    messageWindow: undefined
    , billing: {
        url: _VMSERVER_URL
        , auth: _VM_BILLING_API_AUTH
    }
    , showTariffSettingsWindow: function (cfg) {
        VetManager.GlobalEvents.fireEvent('vm_tariff_open');
        var win = new Ext.Window({
            items: new Tariff.TariffSettingsPanel()
            , width: 800
            , height: 193
            , modal: true
        });
        win.show();
        return win;
    }
    , limitUserError: function () {
        alert(_t('Превышен лимит пользователей для вашего тарифного плана. Смените Ваш тарифный план ' +
            'или удалите/деактивируйте пользователей в модуле \"Управление пользователями\"'));
    }
    , openBillMgrWindow: function() {
        var billingMask = new Ext.LoadMask(Ext.getBody(), {msg: _t(`Авторизация в биллинге`)});
        billingMask.show();
        Ext.Ajax.request({
            url: 'ajax_login.php',
            params: {
                cmd: 'get_billmgr_login_url'
            },
            success: function(response) {
                if (!Common.showErrors(response)) {
                    var data = Ext.decode(response.responseText);
                    if (data.url) {
                        window.open(data.url);
                        return;
                    }
                }
                VetManager.GlobalEvents.fireEvent('vm_goto_billing');
                window.open(_VM_BILLING_URL);
            },
            failure: function() {
                VetManager.GlobalEvents.fireEvent('vm_goto_billing');
                window.open(_VM_BILLING_URL);
            },
            callback: function() {
                billingMask.hide();
            }
        });
    }
    , limitUserErrorTrial: function (sessionChecker) {
        var me = this,
                win;

        me.messageWindow = win = new Ext.Window({
            title: _t('Ошибка тарифа'),
            closable: false,
            resizable: false,
            draggable: false,
            width: 650,
            autoHeight: true,
            layout: 'card',
            activeItem: 0,
            modal: true,
            items: [
                {
                    xtype: 'panel',
                    border: false,
                    height: 180,
                    html:
                            '<div class="current-plan"><div class="current-plan__info"><div class="current-plan__description">'+ _t('Ваш тестовый период пользования программы Vetmanager закончился.')+'</div><span>'+ _t('Вы можете выбрать и оплатить тарифный план, или если вы не успели ознакомиться с программой вы можете написать нам и мы продлим бесплатный период.') +'</span></div></div>',
                    buttons: [
                        {
                            text: _t('Выбрать тариф'),
                            handler: function () {
                                VetManager.GlobalEvents.fireEvent('vm_trial_end');
                                pageLogin.setConfig({
                                    showQRCode: false,
                                    redirectToBillMgrAfterLogin: true,
                                    hideHelpsPanel: true
                                });
                                pageLogin.initLoginDialog();
                                pageLogin.loginDlg.show(Ext4.getBody());
                            }
                        },
                        {
                            text: _t('Обновить данные тарифа'),
                            handler: function() {
                                win.el.mask(_t('Обновление данных тарифа'));
                                Ext.connectSessionChecker.checkTariff(function() {
                                    window.location.href = 'tariff.php';
                                }, true);
                            }
                        },
                        {
                            text: _t('Написать нам'),
                            handler: function () {
                                window.open('https://t.me/vetmanagerhelpbot', '_blank');
                            }
                        }
                    ]
                }
            ]
        });
        win.show();
    }
    , tariffEndValid: function (sessionChecker) {
        var me = this,
                win;

        me.messageWindow = win = new Ext.Window({
            title: _t('Ошибка тарифа'),
            closable: false,
            resizable: false,
            draggable: false,
            width: 550,
            autoHeight: true,
            layout: 'card',
            activeItem: 0,
            modal: true,
            items: [
                {
                    xtype: 'panel',
                    border: false,
                    height: 180,
                    html:
                        `<div class="current-plan">
                            <div class="current-plan__info">
                                <div class="current-plan__description">
                                    ${_t('У Вас закончился тарифный план.')}
                                </div>
                                <span>
                                    ${_t('Чтобы продлить использование программы, нажмите кнопку Оплатить и авторизуйтесь в программе.')}
                                    <br />
                                    ${_t('Если у вас возникли вопросы, связанные с оплатой, нажмите Написать нам и отправьте свой запрос.')}
                                </span>
                            </div>
                        </div>`,
                    buttons: [
                        {
                            text: _t(`Оплатить`),
                            xtype: 'vetbutton',
                            cls: "btn-select-tariff",
                            handler: function () {
                                VetManager.GlobalEvents.fireEvent('vm_trial_end');
                                pageLogin.setConfig({
                                    showQRCode: false,
                                    redirectToBillMgrAfterLogin: true,
                                    hideHelpsPanel: true
                                });
                                pageLogin.initLoginDialog();
                                pageLogin.loginDlg.show(Ext4.getBody());
                            }
                        },
                        {
                            text: _t(`Обновить данные тарифа`),
                            xtype: 'vetbutton',
                            handler: function() {
                                win.el.mask(_t(`Обновление данных тарифа`));
                                Ext.connectSessionChecker.checkTariff(function() {
                                    window.location.href = 'tariff.php';
                                }, true);
                            }
                        },
                        {
                            text: _t(`Написать нам`),
                            xtype: 'vetbutton',
                            handler: function () {
                                window.open('https://t.me/vetmanagerhelpbot', '_blank');
                            }
                        }
                    ]
                }
            ],
            listeners: {
                show: function() {
                    setTimeout(function() {
                        win.el.mask(_t(`Обновление данных тарифа`));
                        Ext.connectSessionChecker.checkTariff(function() {
                            window.location.href = 'tariff.php';
                        }, true);
                    }, 60*1000)
                }
            },
        });
        win.show();
    }
    , limitUserErrorWin: function (sessionChecker, limit_users, users_registered, previewMode) {
        var me = this,
                win;
        previewMode = previewMode === true;

        me.messageWindow = win = new Ext.Window({
            title: _t('Ошибка тарифа'),
            closable: previewMode,
            resizable: false,
            draggable: false,
            width: 650,
            autoHeight: true,
            layout: 'card',
            modal: true,
            activeItem: 0,
            items: [
                {
                    xtype: 'panel',
                    border: false,
                    height: 120,
                    tpl: new Ext.XTemplate(
                            '<tpl for=".">'
                            , '<div class="current-plan">'
                            , '<div class="current-plan__description">Превышен лимит пользователей!</div>'
                            , '<div class="current-plan__info"><span>Необходимо изменить тариф, или деактивировать {deleteUsersCount} пользователей.</span></div>'
                            , '<div class="current-plan__info" id="current-change_tariff__buttons"></div>'
                            , '</div>'
                            , '</tpl>'
                            ),
                    buttons: [
                        {
                            text: _t('Изменить тариф'),
                            handler: function () {
                                win.hide();
                                VetManager.GlobalEvents.fireEvent('vm_userlimit_reached');
                                window.location.href = 'tariff.php';
                            }
                        },
                        {
                            text: _t('Обновить данные тарифа'),
                            handler: function() {
                                win.el.mask(_t('Обновление данных тарифа'));
                                if (previewMode) {
                                    Ext.connectSessionChecker.checkTariff(function() {
                                        win.close();
                                    }, true);
                                } else {
                                    Ext.connectSessionChecker.checkTariff(function() {
                                        window.location.href = 'tariff.php';
                                    }, true);
                                }
                            }
                        },
                        {
                            text: _t('Редактировать пользователей'),
                            handler: function () {
                                win.layout.setActiveItem(1);
                                win.center();
                            }
                        }
                    ]
                },
                {
                    xtype: 'panel',
                    border: false,
                    layout: 'border',
                    height: 400,
                    items: [
                        {
                            xtype: 'panel',
                            border: false,
                            region: 'north',
                            height: 80,
                            tpl: new Ext.XTemplate(
                                    '<tpl for=".">'
                                    , '<div class="current-plan">'
                                    , '<div class="current-plan__description">Превышен лимит пользователей!</div>'
                                    , '<div class="current-plan__info"><span>Необходимо деактивировать {deleteUsersCount} пользователей.</span></div>'
                                    , '<div class="current-plan__info" id="current-change_tariff__buttons"></div>'
                                    , '</div>'
                                    , '</tpl>'
                                    )
                        },
                        {
                            xtype: 'grid',
                            border: false,
                            region: 'center',
                            store: new Ext.data.JsonStore({
                                url: 'ajax_access.php',
                                baseParams: {
                                    cmd: 'get_users_for_tariff'
                                },
                                autoLoad: false,
                                root: 'data',
                                fields: [
                                    'id',
                                    'fio',
                                    'role',
                                    'position'
                                ],
                                sortInfo: {
                                    field: 'fio',
                                    direction: 'ASC'
                                },
                                remoteSort: false,
                                reader: new Ext.data.JsonReader(this),
                                listeners: {
                                    load: function (store, recs) {
                                        var deleteUsersCount = recs.length - limit_users;
                                        if (deleteUsersCount > 0) {
                                            me.gridInfo.update({
                                                deleteUsersCount: deleteUsersCount
                                            });
                                        } else {
                                            win.disable();
                                            window.location = '/';
                                        }
                                    }
                                }
                            }),
                            viewConfig: {
                                forceFit: true
                            },
                            columns: [
                                {header: 'ID', dataIndex: 'id', width: 50, fixed: true, sortable: true, hidden: true},
                                {header: _t('ФИО'), dataIndex: 'fio', width: 100, sortable: true},
                                {header: _t('Роль'), dataIndex: 'role', width: 100, sortable: true},
                                {header: _t('Должность'), dataIndex: 'position', width: 100, sortable: true}
                            ],
                            plugins: [
                                {
                                    ptype: 'griddeleterowcolumnplugin',
                                    isSilentRemove: true
                                }
                            ],
                            listeners: {
                                delete_grid_row: function (grid, rec) {
                                    me.removeUser(rec.get('id'));
                                },
                                scope: me
                            }
                        }
                    ]
                }
            ]
        });
        win.on('show', function () {
            win.items.get(0).update({
                deleteUsersCount: users_registered - limit_users
            });
            me.grid = win.items.get(1).items.get(1);
            me.gridInfo = win.items.get(1).items.get(0);
            me.gridInfo.update({
                deleteUsersCount: users_registered - limit_users
            });
            me.grid.store.on('beforeload', function () {
                me.grid.el && me.grid.el.mask();
            });
            me.grid.store.on('load', function () {
                me.grid.el && me.grid.el.unmask();
            });
            me.grid.store.load();
        });
        win.show();
    }
    , planInfo: {
        solo: {
            min: 1
            , max: 1
        }
        , clinica: {
            min: 2
            , max: 5
        }
        , clinicaplus: {
            min: 6
            , max: 60
        }
    }
    , showHiddenMessageWindow: function() {
        var me = this;
        if (me.messageWindow && !me.messageWindow.isDestroyed) {
            me.messageWindow.show();
        }
    }
};
