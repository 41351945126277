/*
 * Russian translation
 * By ZooKeeper (utf-8 encoding)
 * 6 November 2007
 */

if (!_t) { window._t = function() { return arguments[0]; } }

Ext.UpdateManager.defaults.indicatorText = '<div class="loading-indicator">'+_t('Идет загрузка...')+'</div>';

if(Ext.View){
  Ext.View.prototype.emptyText = "";
}

if(Ext.grid.GridPanel){
  Ext.grid.GridPanel.prototype.ddText = _t('{0} выбранных строк');
}

if(Ext.TabPanelItem){
  Ext.TabPanelItem.prototype.closeText = _t('Закрыть эту вкладку');
}

if(Ext.form.Field){
  Ext.form.Field.prototype.invalidText = _t('Значение в этом поле неверное');
}

if(Ext.LoadMask){
  Ext.LoadMask.prototype.msg = _t('Загрузка...');
}

Date.monthNames = [
  _t('Январь'),
  _t('Февраль'),
  _t('Март'),
  _t('Апрель'),
  _t('Май'),
  _t('Июнь'),
  _t('Июль'),
  _t('Август'),
  _t('Сентябрь'),
  _t('Октябрь'),
  _t('Ноябрь'),
  _t('Декабрь')
];

Date.getShortMonthName = function(month) {
  return Date.monthNames[month].substring(0, 3);
};

Date.monthNumbers = {
  Jan : 0,
  Feb : 1,
  Mar : 2,
  Apr : 3,
  May : 4,
  Jun : 5,
  Jul : 6,
  Aug : 7,
  Sep : 8,
  Oct : 9,
  Nov : 10,
  Dec : 11
};

Date.getMonthNumber = function(name) {
  return Date.monthNumbers[name.substring(0, 1).toUpperCase() + name.substring(1, 3).toLowerCase()];
};

Date.dayNames = [
  _t('Воскресенье'),
  _t('Понедельник'),
  _t('Вторник'),
  _t('Среда'),
  _t('Четверг'),
  _t('Пятница'),
  _t('Суббота')
];

Date.getShortDayName = function(day) {
  return Date.dayNames[day].substring(0, 3);
};

if(Ext.MessageBox){
  Ext.MessageBox.buttonText = {
    ok     : _t(`OK`),
    cancel : _t('Отмена'),
    yes    : _t('Да'),
    no     : _t('Нет')
  };
}

if(Ext.util.Format){
  Ext.util.Format.date = function(v, format){
    if(!v) return "";
    if(!(v instanceof Date)) v = new Date(Date.parse(v));
    return v.dateFormat(format || "d.m.Y");
  };
}

if(Ext.DatePicker){
  Ext.apply(Ext.DatePicker.prototype, {
    todayText          : _t('Сегодня'),
    minText            : _t('Эта дата раньше минимальной даты'),
    maxText            : _t('Эта дата позже максимальной даты'),
    disabledDaysText   : "",
    disabledDatesText  : "",
    monthNames         : Date.monthNames,
    dayNames           : Date.dayNames,
    nextText           : _t('Следующий месяц (Control+Вправо)'),
    prevText           : _t('Предыдущий месяц (Control+Влево)'),
    monthYearText      : _t('Выбор месяца (Control+Вверх/Вниз для выбора года)'),
    todayTip           : _t('{0} (Пробел)'),
    format             : "d.m.y",
    okText             : "&#160;OK&#160;",
    cancelText         : _t('Отмена'),
    startDay           : 1
  });
}

if(Ext4.picker.Date){
    Ext4.apply(Ext4.picker.Date.prototype, {
        todayText          : _t('Сегодня'),
        dayNames           : Date.dayNames,
        nextText           : _t('Следующий месяц (Control+Вправо)'),
        prevText           : _t('Предыдущий месяц (Control+Влево)'),
        monthYearText      : _t('Выбор месяца (Control+Вверх/Вниз для выбора года)'),
        todayTip           : _t('{0} (Пробел)'),
        ariaTitle          : '',
        startDay           : 1
    });
}

if(Ext4.form.field.Date){
    Ext4.apply(Ext4.form.field.Date.prototype, {
        startDay           : 1,
        disabledDaysText  : _t('Не доступно'),
        disabledDatesText : _t('Не доступно'),
        invalidText       : _t(`{0} не является правильной датой - дата должна быть указана в формате {1}`),
        format            : "d.m.y",
        altFormats        : "d.m.y|d/m/Y|d-m-y|d-m-Y|d/m|d-m|dm|dmy|dmY|d|Y-m-d",
        minText            : _t('Эта дата раньше минимальной даты'),
        maxText            : _t('Эта дата позже максимальной даты')
    });
}

if (Ext4.Date) {
    Ext4.override(Ext4.Date, {
        monthNames: Date.monthNames
    });
}

if(Ext.PagingToolbar){
  Ext.apply(Ext.PagingToolbar.prototype, {
    beforePageText : _t('с.'),
    afterPageText  : _t('из {0}'),
    firstText      : _t('Первая страница'),
    prevText       : _t('Предыдущая страница'),
    nextText       : _t('Следующая страница'),
    lastText       : _t('Последняя страница'),
    refreshText    : _t('Обновить'),
    displayMsg     : _t('Отображаются записи с {0} по {1}, всего {2}'),
    emptyMsg       : _t('Нет данных для отображения')
  });
}

if(Ext.form.TextField){
  Ext.apply(Ext.form.TextField.prototype, {
    minLengthText : _t('Минимальная длина этого поля {0}'),
    maxLengthText : _t('Максимальная длина этого поля {0}'),
    blankText     : _t('Это поле обязательно для заполнения'),
    regexText     : "",
    emptyText     : null
  });
}

if(Ext.form.NumberField){
  Ext.apply(Ext.form.NumberField.prototype, {
    minText : _t('Значение этого поля не может быть меньше {0}'),
    maxText : _t('Значение этого поля не может быть больше {0}'),
    nanText : _t('{0} не является числом')
  });
}

if(Ext.form.DateField){
  Ext.apply(Ext.form.DateField.prototype, {
    disabledDaysText  : _t('Не доступно'),
    disabledDatesText : _t('Не доступно'),
    minText           : _t('Дата в этом поле должна быть позже {0}'),
    maxText           : _t('Дата в этом поле должна быть раньше {0}'),
    invalidText       : _t(`{0} не является правильной датой - дата должна быть указана в формате {1}`),
    format            : "d.m.y",
    altFormats        : "d.m.y|d/m/Y|d-m-y|d-m-Y|d/m|d-m|dm|dmy|dmY|d|Y-m-d"
  });
}

if(Ext.form.ComboBox){
  Ext.apply(Ext.form.ComboBox.prototype, {
    loadingText       : _t('Загрузка...'),
    valueNotFoundText : undefined
  });
}

if(Ext.form.VTypes){
  Ext.apply(Ext.form.VTypes, {
    emailText     : _t(`Это поле должно содержать адрес электронной почты в формате \"user@domain.com\"`),
    urlText       : _t(`Это поле должно содержать URL в формате \"http://www.domain.com\"`),
    alphaText     : _t(`Это поле должно содержать только латинские буквы и символ подчеркивания \"_\"`),
    alphanumText  : _t(`Это поле должно содержать только латинские буквы, цифры и символ подчеркивания \"_\"`)
  });
}

if(Ext.form.HtmlEditor){
  Ext.apply(Ext.form.HtmlEditor.prototype, {
    createLinkText : _t('Пожалуйста введите адрес:'),
    buttonTips : {
      bold : {
        title: _t('Полужирный (Ctrl+B)'),
        text: _t('Применение полужирного начертания к выделенному тексту.'),
        cls: 'x-html-editor-tip'
      },
      italic : {
        title: _t('Курсив (Ctrl+I)'),
        text: _t('Применение курсивного начертания к выделенному тексту.'),
        cls: 'x-html-editor-tip'
      },
      underline : {
        title: _t('Подчеркнутый (Ctrl+U)'),
        text: _t('Подчеркивание выделенного текста.'),
        cls: 'x-html-editor-tip'
      },
      increasefontsize : {
        title: _t('Увеличить размер'),
        text: _t('Увеличение размера шрифта.'),
        cls: 'x-html-editor-tip'
      },
      decreasefontsize : {
        title: _t('Уменьшить размер'),
        text: _t('Уменьшение размера шрифта.'),
        cls: 'x-html-editor-tip'
      },
      backcolor : {
        title: _t('Заливка'),
        text: _t(`Изменение цвета фона для выделенного текста или абзаца.`),
        cls: 'x-html-editor-tip'
      },
      forecolor : {
        title: _t('Цвет текста'),
        text: _t('Измение цвета текста.'),
        cls: 'x-html-editor-tip'
      },
      justifyleft : {
        title: _t('Выровнять текст по левому краю'),
        text: _t('Выровнивание текста по левому краю.'),
        cls: 'x-html-editor-tip'
      },
      justifycenter : {
        title: _t('По центру'),
        text: _t('Выровнивание текста по центру.'),
        cls: 'x-html-editor-tip'
      },
      justifyright : {
        title: _t('Выровнять текст по правому краю'),
        text: _t('Выровнивание текста по правому краю.'),
        cls: 'x-html-editor-tip'
      },
      insertunorderedlist : {
        title: _t('Маркеры'),
        text: _t('Начать маркированный список.'),
        cls: 'x-html-editor-tip'
      },
      insertorderedlist : {
        title: _t('Нумерация'),
        text: _t('Начать нумернованный список.'),
        cls: 'x-html-editor-tip'
      },
      createlink : {
        title: _t('Вставить гиперссылку'),
        text: _t('Создание ссылки из выделенного текста.'),
        cls: 'x-html-editor-tip'
      },
      sourceedit : {
        title: _t('Исходный код'),
        text: _t('Переключиться на исходный код.'),
        cls: 'x-html-editor-tip'
      }
    }
  });
}

if(Ext.grid.GridView){
  Ext.apply(Ext.grid.GridView.prototype, {
    sortAscText  : _t('Сортировать по возрастанию'),
    sortDescText : _t('Сортировать по убыванию'),
    lockText     : _t('Закрепить столбец'),
    unlockText   : _t('Снять закрепление столбца'),
    columnsText  : _t('Столбцы')
  });
}

if(Ext.grid.GroupingView){
  Ext.apply(Ext.grid.GroupingView.prototype, {
    emptyGroupText : _t('(Пусто)'),
    groupByText    : _t('Группировать по этому полю'),
    showGroupsText : _t('Отображать по группам')
  });
}

if(Ext.grid.PropertyColumnModel){
  Ext.apply(Ext.grid.PropertyColumnModel.prototype, {
    nameText   : _t('Название'),
    valueText  : _t('Значение'),
    dateFormat : "d.m.Y"
  });
}

if(Ext.SplitLayoutRegion){
  Ext.apply(Ext.SplitLayoutRegion.prototype, {
    splitTip            : _t('Тяните для изменения размера.'),
    collapsibleSplitTip : _t(`Тяните для изменения размера. Двойной щелчок спрячет панель.`)
  });
}

if(Ext.layout.BorderLayout && Ext.layout.BorderLayout.SplitRegion){
  Ext.apply(Ext.layout.BorderLayout.SplitRegion.prototype, {
    splitTip            : _t('Тяните для изменения размера.'),
    collapsibleSplitTip : _t(`Тяните для изменения размера. Двойной щелчок спрячет панель.`)
  });
}
