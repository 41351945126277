Ext.ns('Tariff');

Tariff.CurrentPlanStore = Ext.extend(Ext.data.JsonStore, {
    url: 'ajax_access.php'
    , baseParams: {cmd: 'check_tariff'}
    , root: 'tariff_data'
    , fields: [
        'id'
        , 'name'
        , 'valid_untill'
        , 'users_allowed'
        , 'last_paid_period'
        , 'users_registered'
    ]
    , constructor: function(config){
        Ext.data.JsonStore.superclass.constructor.call(this, Ext.apply(config, {
            reader: new Ext.data.JsonReader(this)
        }));
    } 
});

Tariff.ProductsStore =  Ext.extend(Ext.util.Observable, {    
    prolongationTypes: [
        "VETMANAGER ONLINE"
        , "VETMANAGER SERVER"
        , "VETMANAGER"
    ]
    , isLocalLetters: [
        "VMS"
        , 'SKM'
        , 'SKY'
        , 'SKT'
        , 'SPM'
        , 'SPY'
        , 'SPT'
    ]
    , changeTariffTypes: [
        _t(`Добавление пользователей, тариф \'Клиника\', оплачивался помесячно`)
        , _t(`Добавление пользователей, тариф \'Клиника\', оплачивался за год`)
        , _t(`Добавление пользователей, тариф \'Клиника\', оплачивался за 2 года`)
        , _t(`Добавление пользователей, тариф \'Клиника+\', оплачивался помесячно`)
        , _t(`Добавление пользователей, тариф \'Клиника+\', оплачивался за год`)
        , _t(`Добавление пользователей, тариф \'Клиника+\', оплачивался за 2 года`)  
    ]
    , constructor: function(config){
        this.addEvents('load', 'error_load');
        this.billingUrl = Tariff.Common.billing.url;
        Tariff.ProductsStore.superclass.constructor.call(this, config)
    }  
    , load: function(){
        var self = this
        , loadProlongation = false
        , loadUsersAdd = false
        , pages = 6
        , prolongationResult = []
        , userAddResult = [];
        
        if (!Tariff.ProductsStore.prototype.successLoad && !Tariff.ProductsStore.prototype.startLoad) {
            Tariff.ProductsStore.prototype.startLoad = true;
            for (var i = 1; i <= pages; i++) {
                jQuery.ajax({
                    url: this.billingUrl + '/store/api/rest/products?categories=4&limit=100&page='+ i + '&dc' + (new Date())*1
                    , crossDomain: true
                    , success : function(d){
                        Ext.apply(Tariff.ProductsStore.prototype.products,d);
                        prolongationResult.push(true);
                        if (prolongationResult.length === pages) loadProlongation = true;
                        if (loadProlongation && loadUsersAdd) {
                            Tariff.ProductsStore.prototype.successLoad = true;
                            self.fireEvent('load', Tariff.ProductsStore.prototype.products);
                        }
                    }
                    , error: function(e) {

                        self.fireEvent('error_load');
                    }
                    , dataType: 'json'
                });            
            }
            for (var i = 1; i <= pages; i++) {
                jQuery.ajax({
                    url: this.billingUrl + '/store/api/rest/products?categories=5&limit=100&page='+ i + '&dc' + (new Date())*1
                    , crossDomain: true
                    , success : function(d){
                        Ext.apply(Tariff.ProductsStore.prototype.products, d);
                        userAddResult.push(true);
                        if (userAddResult.length === pages)  loadUsersAdd = true;
                        if (loadProlongation && loadUsersAdd) {
                            Tariff.ProductsStore.prototype.successLoad = true;
                            self.fireEvent('load', Tariff.ProductsStore.prototype.products);
                        }
                    }
                    , error: function(e) {
                        self.fireEvent('error_load');
                    }
                    , dataType: 'json'
                });   
            }            
        } else if (Tariff.ProductsStore.prototype.successLoad) {
            Ext.defer(function(){
                self.fireEvent('load', Tariff.ProductsStore.prototype.products);
            }, 200, this);           
        } else if (Tariff.ProductsStore.prototype.startLoad){
            var runner = new Ext.util.TaskRunner()
                task = {
                    run: function(){
                        if (Tariff.ProductsStore.prototype.successLoad) {
                            self.fireEvent('load', Tariff.ProductsStore.prototype.products);
                            runner.stopAll();
                        }
                    }
                    , interval: 200
                    , scope: this
                };
            runner.start(task);
        }
    }
    , parseSKU: function(sku){
        var kodInfo = [
            {
                name: 'type'
                , start: 0
                , length: 3
                , getValue: function(val){
                    var res = val;
                    switch(val) {
                        case 'VMO':
                            res = "VETMANAGER ONLINE";
                            break;
                        case 'VMS':
                            res = "VETMANAGER SERVER";
                            break;
                        case 'VMD':
                            res = "VETMANAGER"
                            break;
                        case 'AKM':
                            res = _t(`Добавление пользователей, тариф \'Клиника\', оплачивался помесячно`);
                            break;
                        case 'AKY':
                            res = _t(`Добавление пользователей, тариф \'Клиника\', оплачивался за год`);
                            break;                        
                        case 'AKT':
                            res = _t(`Добавление пользователей, тариф \'Клиника\', оплачивался за 2 года`);
                            break;                        
                        case 'APM':
                            res = _t(`Добавление пользователей, тариф \'Клиника+\', оплачивался помесячно`);
                            break;                        
                        case 'APY':
                            res = _t(`Добавление пользователей, тариф \'Клиника+\', оплачивался за год`);
                            break;                        
                        case 'APT':
                            res = _t(`Добавление пользователей, тариф \'Клиника+\', оплачивался за 2 года`);
                            break;     
                        case 'SKM':
                            res = _t(`Добавление пользователей, тариф \'Клиника\', оплачивался помесячно`);
                            break;
                        case 'SKY':
                            res = _t(`Добавление пользователей, тариф \'Клиника\', оплачивался за год`);
                            break;                        
                        case 'SKT':
                            res = _t(`Добавление пользователей, тариф \'Клиника\', оплачивался за 2 года`);
                            break;                        
                        case 'SPM':
                            res = _t(`Добавление пользователей, тариф \'Клиника+\', оплачивался помесячно`);
                            break;                        
                        case 'SPY':
                            res = _t(`Добавление пользователей, тариф \'Клиника+\', оплачивался за год`);
                            break;                        
                        case 'SPT':
                            res = _t(`Добавление пользователей, тариф \'Клиника+\', оплачивался за 2 года`);
                            break;                        
                    }
                    return res;
                }
            }
            , {
                name: 'users_allowed'
                , start: 4
                , length: 2
                , getValue: function(val){
                    return val.replace(/\D/ig)*1;
                }
            }
            , {
                name: 'period_length'
                , start: 7
                , length: 2
                , getValue: function(val){
                    return val.replace(/\D/ig)*1;
                }
            }
            , {
                name: 'period_unit'
                , start: 9
                , length: 1
                , getValue: function(val){
                    switch(val) {
                        case 'M':
                            return 'month';
                        case 'Y':
                            return 'year';
                    }
                    return val;
                }
            }
        ];
        var skuProperty = {};
        var i = kodInfo.length;
        while(i--) {
            var property = kodInfo[i];
            skuProperty[property.name] = property.getValue(sku.substr(property.start, property.length));
        }
        return skuProperty;
    }   
    , isProlongationTarrif: function(productType){
        return this.prolongationTypes.indexOf(productType) !== -1;
    }
    , isChangeTarrif: function(productType){
        return this.changeTariffTypes.indexOf(productType) !== -1;
    }    
    , getTariffData: function(filter){
        var tarrifs = [];
        
        for (var key in this.products) {            
            if (this.products.hasOwnProperty(key)) {                
                var product = this.products[key];
                var productProperty = this.parseSKU(product.sku);
                if (this.isFilterMatch(product, filter)) {
                    tarrifs.push([product.entity_id, product.name, product, productProperty]);
                }                
            }
        }
        return tarrifs;        
    }
    , isFilterMatch: function(product, filter){
        var result = false
            , productProperty = this.parseSKU(product.sku)
            , isLocalSku = this.isLocalSku(product.sku);  
 // console.log(GlobalProperties.get('is_local_version') * 1 , isLocalSku);
        if (GlobalProperties.get('is_local_version') * 1 != isLocalSku) {
            return false;
        } 
        if (filter.is_change_tariff && this.isChangeTarrif(productProperty.type)) {
            result = true;
        } else if (!filter.is_change_tariff && !this.isChangeTarrif(productProperty.type)) {
            result = true;
        }
       
        if (result && typeof filter.period === 'string') {
            if (filter.period === '2year' && productProperty.period_unit === "year" && productProperty.period_length === 2) {
                result = true;
            } else if (filter.period === 'year' && productProperty.period_unit === "year" && productProperty.period_length === 1){
                result = true;
            } else if (filter.period === 'month' && productProperty.period_unit === "month") {
                result = true;
            } else {
                result = false;
            }       
        }        
    
        if (typeof filter.sku === 'string' ) {
            result = filter.sku.slice(1,10) === product.sku.slice(1,10);
        }  
        if (result && typeof filter.users_allowed  === 'number') {
            result = productProperty.users_allowed === filter.users_allowed;
        }
        
        return result;
    }
    , isLocalSku: function(sku) {
        var word = sku.slice(0, 3)
            , index = [
                "VMS"
                , 'SKM'
                , 'SKY'
                , 'SKT'
                , 'SPM'
                , 'SPY'
                , 'SPT'
            ].indexOf(word);
        return index !== -1;
    } 
    , getChangeTariffProducts: function(lastPaidData){
        return this.getTariffData({
            period: lastPaidData
            , is_change_tariff: true
        });
    }
    , getMaxAllowedAddUsers: function(){
        var maxAllowed = 0;
        for (var key in this.products) {
            if (this.products.hasOwnProperty(key)) {
                var product = this.products[key];
                var productProperty = this.parseSKU(product.sku);
                productProperty.users_allowed = productProperty.users_allowed*1;
                if (maxAllowed < productProperty.users_allowed) {
                    maxAllowed = productProperty.users_allowed;
                }
            }
        }
        return maxAllowed;
    }
});

Tariff.ProductsStore.prototype.successLoad = false;
Tariff.ProductsStore.prototype.startLoad = false;
Tariff.ProductsStore.prototype.products = {};