Ext.ns('Tariff');
Tariff.PayWindow = Ext.extend(Ext.Window, {
    title: _t('Оплата тарифа')
    , closable: false
    , border: false
    , width: 800
    , height: 600
    , modal: true
    , initComponent: function(config){
        Tariff.PayWindow.superclass.initComponent.call(this, config);    
        this.currentInfoPanel = new Tariff.CurrentPlanInfo();
        this.prolongationPanel = new Tariff.Prolongation();
        this.add(
            new Ext.Panel({
                layout: 'anchor'
                , items: [
                    this.currentInfoPanel
                    // , this.prolongationPanel
                ]
                , border: false
                , frame: false
                , height: 590
            })
        );
        this.doLayout();        
    }
});