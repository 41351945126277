Ext.LockDocument = new  function () {
    let me = this;
    me.TIMEOUT_OPEN_DOCUMENT = 5 * 60; // 5 minute for unactive with document;
    me.TIMEOUT_OPEN_DOCUMENT_LONG = 20 * 60; // 20 minute for unactive with document;
    me.init = function () {
        Ext.onReady(function () {
            if (typeof VetManager != 'undefined') {
                VetManager.eventManager.addEvents(
                    'lock_document',
                    'unlock_document',
                    'check_for_lock_document',
                    'check_and_lock_document'
                );
            }
        });
    };
    me.lock = function (data, success, error, scope) {
        me.request({
            cmd: 'lock',
            data: data,
            success: success,
            error: error,
            eventName: 'lock_document',
            scope: scope || me
        });
    };

    me.request = function (params, callback) {
        Ext.Ajax.request({
            url: 'ajax_lock.php',
            scope: params.scope || me,
            success: function (r) {
                let data = Ext.decode(r.responseText);
                if (params.cmd.indexOf('check') >= 0 && data.data.check != 1) {
                    if (data.data.msg) {
                        Ext.MsgManager.alertError(_t('Ошибка'), data.data.msg);
                    } else {
                        Ext.MsgManager.alertError(
                            _t('Ошибка'),
                            _t(`В данный момент документ нельзя редактировать, попробуйте позже`)
                        );
                    }
                } else {
                    data.state = 'success';
                    if (params.success) {
                        params.success.call(params.scope || me, data);
                    }
                    if (callback) {
                        callback(data);
                    }
                    VetManager.eventManager.fireEvent(params.eventName, data);
                }
            },
            error: function () {
                let data = params.data.state = 'error';
                if (params.error) {
                    params.error(data);
                }
                if (callback) {
                    callback(data);
                }
                VetManager.eventManager.fireEvent(params.eventName, data);
            },
            params: {
                cmd: params.cmd,
                SUPERAJAX: 1,
                params: Ext.encode(params.data)
            }
        });
    };

    me.unlock = function (data, success, error, scope) {
        me.request({
            cmd: 'unlock',
            data: data,
            success: success,
            error: error,
            eventName: 'unlock_document',
            scope: scope || me
        });
    };

    me.check = function (data, success, error, scope) {
        me.request({
            cmd: 'check',
            data: data,
            success: success,
            error: error,
            eventName: 'check_for_lock_document',
            scope: scope || me
        });
    };

    me.unlockAll = function (success, error, scope) {
        me.request({
            cmd: 'unlockall',
            data: {session_key: 'myself'},
            success: success,
            error: error,
            eventName: 'unlock_document',
            scope: scope || me
        });
    };

    me.checkAndLock = function (data, success, error, scope) {
        me.request({
            cmd: 'check_and_lock',
            data: data,
            success: success,
            error: error,
            eventName: 'check_and_lock_document',
            scope: scope || me
        });
    }
};

Ext.LockDocument.init();